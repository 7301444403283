import React from 'react'
import {
    Badge,
    IconButton,
    SvgIcon,
    Tooltip,
    makeStyles,
} from '@material-ui/core'
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import {
    useTranslation,
    useWhiteLabelServiceSettings
} from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { RedirectPage } from 'src/common/LayoutCommon';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
}))

function FAQ({ defaultPagePath }) {
    const history = useHistory()
    const classes = useStyles()
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")

    function makeTranslatedTextsObject() {
        let faq = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.FAQ
        )

        return { faq }
    }

    function redirectPage() {
        let faqPath = "";
        let wbFaqPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbFaqPath = whiteLabelServiceSettings[0].custom.faq
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/faq' : defaultPagePath;
        RedirectPage(history, faqPath, defaultPagePath, wbFaqPath)
    }

    return (
        <Tooltip title={translatedTextsObject.faq}>
            <Badge color='primary' classes={{ badge: classes.badge }}            >
                <IconButton onClick={redirectPage}>
                    <SvgIcon fontSize='small' >
                        <LiveHelpOutlinedIcon />
                    </SvgIcon>
                </IconButton>
            </Badge>
        </Tooltip>
    )
}

export default FAQ;