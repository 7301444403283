import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation, useWhiteLabelServiceSettings } from 'cng-web-lib'
import { makeStyles, Button } from '@material-ui/core'
import { RedirectPage } from 'src/common/LayoutCommon';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
    button: {
        fontSize: 16,
        fontWeight: 400,
        textTransform: 'none',
        transition: 'all 0.15s linear',
        borderRadius: 10,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#113448',
            boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08), 0px 6px 12px -2px rgba(0, 0, 0, 0.09), 0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
        }
    }
}))

function ContactUs({ defaultPagePath }) {
    const classes = useStyles()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const history = useHistory()

    function makeTranslatedTextsObject() {
        let contactUs = translate(Namespace.HOME_PAGE, HomePageKeys.CONTACT_US)
        return { contactUs }
    }

    function redirectPage() {
        let contactUsPath = '/shared/contact-us'

        let wbContactUsPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbContactUsPath = whiteLabelServiceSettings[0].custom.contactUs
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/contact-us' : defaultPagePath;
        RedirectPage(history, contactUsPath, defaultPagePath, wbContactUsPath)
    }

    return <Button className={classes.button} onClick={redirectPage} size='medium'>{translatedTextsObject.contactUs}</Button>
}

export default ContactUs;