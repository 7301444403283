import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import {
  AppBar, Box, Toolbar, Hidden, Drawer, Tooltip, SvgIcon, IconButton,
  Button, List, ListItem, ListItemIcon, ListItemText, Divider, makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MenuIcon from '@material-ui/icons/Menu'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import Logo from './Logo'
import Home from 'src/layouts/CustomCngLayout/RbiCustomTopBar/Home'
import ContactUs from 'src/layouts/CustomCngLayout/RbiCustomTopBar/ContactUs'
import AboutUs from 'src/layouts/CustomCngLayout/RbiCustomTopBar/AboutUs'
import Webform from 'src/layouts/CustomCngLayout/RbiCustomTopBar/Webform'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const { button: { CngButton } } = components

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.common.white,
    height: 97
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  login: {
    backgroundColor: '#fff',
    borderColor: '#d25d13',
    borderWidth: 2,
    borderRadius: 999,
    borderStyle: 'solid',
    boxShadow: 'none',
    color: '#d25d13',
    fontSize: 16,
    lineHeight: 1.2,
    minHeight: 'unset',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#d25d13',
      boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
        0px 6px 12px -2px rgba(0, 0, 0, 0.09),
        0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
    }
  },
  hamburgerMenu: {
    left: -10,
    position: 'relative',
    '& svg': {
      color: theme.palette.common.black
    }
  }
}))

function TopBar({ className, ...rest }) {
  const classes = useStyles()
  const history = useHistory()

  const [hamIcon, setHamIcon] = useState(false)

  const webformUrl = 'https://melystorageaccount.z9.web.core.windows.net/dashboard/emanifestwebform'

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  const sideBar = (
    <Box p={2} style={{ background: '#efefef', width: 250, height: '100%' }} onClick={() => setHamIcon(false)}>
      <List>
        <ListItem disablePadding>
          <Button onClick={() => history.push('rbi')}>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-house" size='lg' /></ListItemIcon>
            <ListItemText primary='Home' />
          </Button>
        </ListItem>

        <ListItem disablePadding>
          <Button onClick={() => history.push('rbi-contact-us')}>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-phone" size='lg' /></ListItemIcon>
            <ListItemText primary='Contact Us' />
          </Button>
        </ListItem>

        <ListItem disablePadding>
          <Button onClick={() => history.push('rbi-about-us')}>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-user-group" size='lg' /></ListItemIcon>
            <ListItemText primary='About Us' />
          </Button>
        </ListItem>

        <ListItem disablePadding>
          <Button onClick={() => openWindow(webformUrl)}>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-file-invoice" size='lg' /></ListItemIcon>
            <ListItemText primary='Webform' />
          </Button>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem disablePadding>
          <Button component={RouterLink} to='/login'>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-right-to-bracket" size='lg' /></ListItemIcon>
            <ListItemText primary='Login' />
          </Button>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <AppBar className={clsx(className, classes.appBar)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box>
          <Logo className={classes.logo} style={{ display: 'flex' }} />
        </Box>

        <Hidden smDown>
          <Box display='flex' mx={4} style={{ gap: 16 }}>
            <Home defaultPagePath={'rbi'} />
            <ContactUs defaultPagePath={'rbi-contact-us'} />
            <AboutUs defaultPagePath={'rbi-about-us'} />
            <Webform targetUrl={webformUrl} />
          </Box>

          <Box flexGrow={1} />

          <CngButton className={classes.login} component={RouterLink} to='/login'>Login</CngButton>
        </Hidden>

        <Hidden mdUp>
          <Box flexGrow={1} />
          <Tooltip title='Toggle Sidebar' className={classes.hamburgerMenu}>
            <IconButton
              className={clsx(classes.menuButton, hamIcon ? 'state-expanded' : 'state-collapsed')}
              color='inherit' onClick={() => setHamIcon(true)}
            >
              <SvgIcon>{hamIcon ? <MenuIcon /> : <MenuOpenIcon />}</SvgIcon>
            </IconButton>
          </Tooltip>
        </Hidden>
      </Toolbar>
      <Drawer anchor='right' open={hamIcon} onClose={() => setHamIcon(false)}>{sideBar}</Drawer>
    </AppBar>
  )
}

TopBar.propTypes = { className: PropTypes.string }

export default TopBar
