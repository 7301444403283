import React from 'react'
import {
    Badge,
    IconButton,
    SvgIcon,
    Tooltip,
    makeStyles,
    Button,
    useTheme,
    useMediaQuery
} from '@material-ui/core'
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import {
    useTranslation,
    useWhiteLabelServiceSettings
} from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { RedirectPage } from 'src/common/LayoutCommon';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
    button: {
        color: 'inherit',
        fontSize: 16,
        fontWeight: 600,
        textTransform: 'none'
    }
}))

function ProductInfo({ defaultPagePath }) {
    const history = useHistory()
    const classes = useStyles()
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const theme = useTheme()
    const mdUp = useMediaQuery(theme.breakpoints.up('md'))

    function makeTranslatedTextsObject() {
        let productInfo = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.PRODUCT_INFO
        )

        return { productInfo }
    }

    function redirectPage() {
        let productInfoPath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/product-info' : defaultPagePath;
        let wbProductInfoPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbProductInfoPath = whiteLabelServiceSettings[0].custom.productInfo
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/product-info' : defaultPagePath;
        RedirectPage(history, productInfoPath, defaultPagePath, wbProductInfoPath)
    }

    return mdUp ? (
      <Button className={classes.button} onClick={redirectPage}>
        {translatedTextsObject.productInfo}
      </Button>
    ) : (
      <IconButton onClick={redirectPage} size='small'>
        <InfoOutlined fontSize='small' />
      </IconButton>
    )
}

export default ProductInfo;