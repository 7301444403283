import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation, useWhiteLabelServiceSettings } from 'cng-web-lib'
import { makeStyles, Button, useTheme, useMediaQuery } from '@material-ui/core'
import { RedirectPage } from 'src/common/LayoutCommon';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
    button: {
        fontSize: 16,
        textTransform: 'none',
        transition: 'all 0.15s linear',
        borderRadius: 10,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#c09b30',
            boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08), 0px 6px 12px -2px rgba(0, 0, 0, 0.09), 0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
        }
    }
}))

function ProductInfo({ defaultPagePath }) {
    const history = useHistory()
    const classes = useStyles()
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings('custom-layout')
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let productInfo = translate(Namespace.HOME_PAGE, HomePageKeys.PRODUCT_INFO)
        return { productInfo }
    }

    function redirectPage() {
        let wbProductInfoPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbProductInfoPath = whiteLabelServiceSettings[0].custom.productInfo
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/product-info' : defaultPagePath;
        RedirectPage(history, '', defaultPagePath, wbProductInfoPath)
    }

    return smUp ? (<Button className={classes.button} onClick={redirectPage}>{translatedTextsObject.productInfo}</Button>) : <></>
}

export default ProductInfo;