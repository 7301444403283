import React from 'react'

function Logo(props) {
  const { className } = props

  return (
    <img alt='RAF Logo' src='/na-mig/static/rafAssets/logo-raf-home.png' className={className} style={{ width: 'auto', height: 'auto', maxHeight: '50px' }} />
  )
}

export default Logo
