import React from 'react'
import {
    Badge,
    IconButton,
    SvgIcon,
    Tooltip,
    makeStyles,
} from '@material-ui/core'
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import {
    useTranslation,
    useWhiteLabelServiceSettings
} from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { RedirectPage } from 'src/common/LayoutCommon';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
    button: {
        color: theme.palette.common.white,
        fontWeight: 600,
        textTransform: 'none'
    }
}))

function ProductInfo({ defaultPagePath }) {
    const history = useHistory()
    const classes = useStyles()
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")

    function makeTranslatedTextsObject() {
        let productInfo = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.PRODUCT_INFO
        )

        return { productInfo }
    }

    function redirectPage() {
        let productInfoPath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/product-info' : defaultPagePath;
        let wbProductInfoPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbProductInfoPath = whiteLabelServiceSettings[0].custom.productInfo
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/product-info' : defaultPagePath;
        RedirectPage(history, productInfoPath, defaultPagePath, wbProductInfoPath)
    }

    return (
      <Tooltip title={translatedTextsObject.productInfo}>
        <Badge color='primary' classes={{ badge: classes.badge }}>
          <IconButton onClick={redirectPage}>
            <SvgIcon fontSize='small'>
              <InfoOutlined />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
    )
}

export default ProductInfo;