import clsx from 'clsx'
import React from 'react'

function Logo(props) {
  const { className } = props

  return (
    <img
      alt='UPS Logo'
      src='/na-mig/static/ups-logo.svg'
      className={clsx('logo-gets', className)}
      style={{ width: 'auto' }}
    />
  )
}

export default Logo
