import { Grid } from '@material-ui/core'
import React from 'react'

function Footer({ children }) {
  return (
    <Grid container justify='center'>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item>
        Copyright {new Date().getFullYear()} GeTS Global Pte Ltd. 
      </Grid>
    </Grid>
  )
}

export default Footer
export { default as ExtendedFooter } from './ExtendedFooter'
