import React from 'react'
import { Grid, Link, makeStyles, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function Logo(props) {
  const { className } = props
  const classes = useStyles()
  const showXpertLogo = useMediaQuery('(min-width:620px)')

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  return (
    <Grid container justify='space-between' alignItems='center'>
      <Grid item>
        <Link className={classes.link} onClick={() => openWindow('http://www.xpertdispatch.com/')}>
          <img alt='FCI Logo' src='/na-mig/static/fciAssets/logo-fci.png' className={className} style={{ height: '100%', width: '100%', maxWidth: '355px' }} />
        </Link>
      </Grid>
      {showXpertLogo &&
        <Grid item>
          <img alt='FCI Logo' src='/na-mig/static/fciAssets/logo-xpertdispatch.png' className={className} style={{ height: '100%', width: '100%', maxWidth: '155px' }} />
        </Grid>
      }
    </Grid>
  )
}

export default Logo
