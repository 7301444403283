import React from 'react'

function Logo(props) {
  const { className } = props

  return (
    <img alt='RBI Logo' src='/na-mig/static/rbiAssets/logo-rbi.png' className={className} style={{ width: 'auto', height: 'auto' }} />
  )
}

export default Logo
