import React from 'react'
import { components } from 'cng-web-lib'
import { Link as RouterLink } from 'react-router-dom'
import { AppBar, Box, Toolbar, Grid, Divider, makeStyles } from '@material-ui/core'

import Logo from './Logo'
import Home from 'src/layouts/CustomCngLayout/FciCustomTopBar/Home'
import ContactUs from 'src/layouts/CustomCngLayout/FciCustomTopBar/ContactUs'
import AboutUs from 'src/layouts/CustomCngLayout/FciCustomTopBar/AboutUs'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const { button: { CngButton } } = components

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
    height: 151
  },
  subToolbar: {
    padding: 5
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  login: {
    backgroundColor: '#fff',
    borderColor: '#113448',
    borderWidth: 2,
    borderRadius: 999,
    borderStyle: 'solid',
    boxShadow: 'none',
    color: '#113448',
    fontSize: 16,
    lineHeight: 1.2,
    minHeight: 'unset',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#113448',
      boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
        0px 6px 12px -2px rgba(0, 0, 0, 0.09),
        0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
    }
  }
}))

function TopBar({ className, ...rest }) {
  const classes = useStyles()

  return (
    <AppBar className={clsx(className, classes.appBar)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item xs={12} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
            <Box>
              <Logo className={classes.logo} />
            </Box>
          </Grid>

          <Grid item xs={12} style={{ backgroundColor: '#b3e6ff' }}>
            <Grid container justify='space-between' className={classes.subToolbar}>
              <Grid item>
                <Box display='flex' mx={1} style={{ gap: 10 }}>
                  <Home defaultPagePath={'fci'} />
                  <Divider orientation='vertical' flexItem />
                  <AboutUs defaultPagePath={'fci-about-us'} />
                  <Divider orientation='vertical' flexItem />
                  <ContactUs defaultPagePath={'fci-contact-us'} />
                </Box>
              </Grid>

              <Grid item>
                <CngButton className={classes.login} component={RouterLink} to='/login' size='medium'>Login</CngButton>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = { className: PropTypes.string }

export default TopBar
