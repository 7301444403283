import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { components, useTranslation } from 'cng-web-lib'
import {
  IconButton, Button, Box, Grid, Typography,
  makeStyles, useTheme, useMediaQuery
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/BuildOutlined'
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import clsx from 'clsx'

const { button: { CngButton }, CngDialog } = components

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  link: {
    color: '#0000ee'
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  button: {
    color: 'inherit',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none'
  },
  upsButton: {
    backgroundColor: '#ffc400',
    borderRadius: 999,
    boxShadow: 'none',
    color: '#121212',
    '&:hover': {
      backgroundColor: '#ffd033',
      boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
        0px 6px 12px -2px rgba(0, 0, 0, 0.09),
        0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
    },
    '&:focus': {
      backgroundColor: '#ffa200'
    }
  }
}))

function TradeResources() {
  const [dialog, setDialog] = useState(false)
  const classes = useStyles()
  const { translate } = useTranslation(Namespace.HOME_PAGE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  function makeTranslatedTextsObject() {
    const tradeResources = translate(
      Namespace.HOME_PAGE,
      HomePageKeys.TRADE_RESOURCES
    )

    return { tradeResources }
  }

  return (
    <>
      {mdUp ? (
        <Button className={classes.button} onClick={() => setDialog(true)}>
          {translatedTextsObject.tradeResources}
        </Button>
      ) : (
        <IconButton onClick={() => setDialog(true)} size='small'>
          <InfoIcon fontSize='small' />
        </IconButton>
      )}
      <Dialog
        dialogTitle={translatedTextsObject.tradeResources}
        open={dialog}
        onClose={() => setDialog(false)}
      />
    </>
  )
}

function Dialog(props) {
  const { dialogTitle, open, onClose } = props
  const classes = useStyles()

  function openWindow(url) {
    window.open(url, '_blank', 'noreferrer')
  }

  function downloadFile(filePath) {
    window.open(filePath, '_blank', 'noreferrer')
  }

  return (
    <CngDialog
      dialogTitle={dialogTitle}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      shouldShowCloseButton
      dialogAction={
        <CngButton className={clsx(classes.button, classes.upsButton)} onClick={onClose}>OK</CngButton>
      }
      customDialogContent={
        <Box bgcolor='#f2f1ef' p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={2}>
                <Typography style={{ fontWeight: 700 }}>Tools</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => openWindow('http://www.ups.com/ga/MetricConversion?loc=en_US')}>
                    UPS Unit of Measure Conversion Tool
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => openWindow('https://pars.ups.com/pars/')}>
                    Track your PARS
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => openWindow('https://paps.ups.com/paps/')}>
                    Track your PAPS
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={2}>
                <Typography style={{ fontWeight: 700 }}>Regulatory Resources</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => openWindow('https://www.cbsa-asfc.gc.ca/prog/aci-manif-ipec/menu-eng.html')}>
                    CBSA ACI eManifest
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => openWindow('https://www.cbp.gov/trade/automated')}>
                    CBP ACE eManifest
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box mb={2}>
                <Typography style={{ fontWeight: 700 }}>Trade Information</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => openWindow('https://www.ups.com/us/en/supplychain/resources/news-and-market-updates.page')}>
                    UPS Trade Broadcast
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={18} lg={12}>
              <Box mb={2}>
                <Typography style={{ fontWeight: 700 }}>Document Download</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <Typography style={{ fontWeight: 400 }}>ACE</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/UPS Next-Gen HWY Quick Guide.docx')}>
                    Highway Quick Guide
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/UPS_ACE_Truck_Upload.csv')}>
                    ACE Truck Upload
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/UPS_ACE_Trailer_Upload.csv')}>
                    ACE Trailer Upload
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/UPS_ACE_DriverCrew_Upload.csv')}>
                    ACE Driver/Crew Upload
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/UPS_ACE_Shipment_Upload.csv')}>
                    ACE Shipment Upload
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>  
              <Box>
                <Typography style={{ fontWeight: 400 }}>ACI</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/UPS Next-Gen HWY Quick Guide.docx')}>
                    Highway Quick Guide
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/UPS_Cargo_Upload.csv')}>
                    ACI Cargo Upload
                  </Link>
                </Grid>
              </Grid>  
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <Typography style={{ fontWeight: 400 }}>Available Trade Documentation</Typography>
              </Box> 
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/NANG_NBCI_User_Guide.DOCX')}>
                    NBCI US-CA Invoice User Manual
                  </Link>
                </Grid>
              </Grid>     
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/USInvoice.csv')}>
                    US Invoice Upload
                  </Link>
                </Grid>
              </Grid>       
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/US-PartyMaster.csv')}>
                    US Party Master Upload
                  </Link>
                </Grid>
              </Grid>      
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/US-ProductMaster.csv')}>
                    US Product Master Upload
                  </Link>
                </Grid>
              </Grid>       
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/CanadaInvoiceTemplate.csv')}>
                    Canada Invoice Upload
                  </Link>
                </Grid>
              </Grid>          
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/Canada-PartyMaster.csv')}>
                    Canada Party Master Upload
                  </Link>
                </Grid>
              </Grid>       
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/Canada-ProductMaster.csv')}>
                    Canada Product Master Upload
                  </Link>
                </Grid>
              </Grid>         
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/NANG_SBCI_User_Guide.DOCX')}>
                    SBCI Invoice User Manual
                  </Link>
                </Grid>
              </Grid>           
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/SB-Invoice.csv')}>
                    US-SB Invoice Upload
                  </Link>
                </Grid>
              </Grid>       
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/SB-PartyMaster.csv')}>
                    US-SB Party Master Upload
                  </Link>
                </Grid>
              </Grid>        
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link 
                    className={classes.link}
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/SB-ProductMaster.csv')}>
                    US-SB Product Master Upload
                  </Link>
                </Grid>
              </Grid>                                                                                                                     
            </Grid>            
          </Grid>
        </Box>
      }
    />
  )
}

export default TradeResources
