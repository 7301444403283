import React from 'react'
import { Container, Grid, Box, Link, Typography, Divider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#dadada',
    padding: '1rem',
    '& .MuiTypography-root': {
      fontSize: '10px',
      color: '#656565'
    }
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function Footer() {
  const classes = useStyles()

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  function openPdf(pdf) {
    window.open(process.env.PUBLIC_URL + pdf, '_blank', 'popup')
  }

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container justify='space-between' spacing={1}>
          <Grid item xs={12} md='auto'>
            <Box display='flex' style={{ gap: 5 }}>
              <Link className={classes.link} onClick={() => openWindow('https://farrow.com/')}>
                <Typography>Russell A. Farrow Limited</Typography>
              </Link>

              <Divider orientation='vertical' flexItem />

              <Link className={classes.link} onClick={() => openPdf('/static/rafAssets/Terms_and_Conditions_of_Use-Russell_A_Farrow_Limited-Final_09.15.10.pdf')}>
                <Typography>Terms and Conditions</Typography>
              </Link>

              <Divider orientation='vertical' flexItem />

              <Link className={classes.link} onClick={() => openPdf('/static/rafAssets/Privacy_Policy-Russell_A_Farrow_Limited-Final_09.15.10.pdf')}>
                <Typography>Privacy Statement</Typography>
              </Link>

              <Divider orientation='vertical' flexItem />

              <Link className={classes.link} onClick={() => openWindow('mailto:acisupport@farrow.com')}>
                <Typography>Contact Us</Typography>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md='auto'>
            <Typography>Copyright Russell A. Farrow Limited 2008. All Rights Reserved</Typography>
          </Grid>
        </Grid>

      </Container>
    </Box>
  )
}

export default Footer
