import React from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router-dom';
import { RedirectPage } from 'src/common/LayoutCommon';
/*import {
    useWhiteLabelServiceSettings
} from 'cng-web-lib'*/
const {
    button: { CngPrimaryButton }
  } = components
function UpdatePassword({defaultPagePath}) {
    console.log("default path",defaultPagePath);
    const history = useHistory();
    //console.log("Inside Update Password");
    function handleClick() {
        let contactUsPath = '/update-password'

        //let wbContactUsPath;
        //if (whiteLabelServiceSettings.length > 0) {
        //    wbContactUsPath = whiteLabelServiceSettings[0].custom.contactUs
        //}

        history.push(contactUsPath)
        //defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/update-password' : defaultPagePath;
        //RedirectPage(history, contactUsPath,defaultPagePath, wbContactUsPath)
    }
    return (
       <CngPrimaryButton onClick={handleClick}>
           Update Password
       </CngPrimaryButton>
    
    )
}
export default UpdatePassword;