import React, { useState, useEffect } from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import { useWhiteLabelServiceSettings } from 'cng-web-lib'
import PrivacyPolicy from './PrivacyPolicy'
import TermsOfUse from './TermsOfUse'
import TermsOfServices from './TermsOfServices'
import Copyright from './Copyright'
import { default as RbiCustomFooter } from 'src/layouts/HomePageLayout/rbi/Footer'
import { default as RafCustomFooter } from 'src/layouts/HomePageLayout/raf/Footer'
import { default as FciCustomFooter } from 'src/layouts/HomePageLayout/fci/Footer'

const components = {
  RbiCustomFooter: RbiCustomFooter,
  RafCustomFooter: RafCustomFooter,
  FciCustomFooter: FciCustomFooter
};


function CustomFooter() {
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
  const [customFooterId, setCustomFooterId] = useState(null)
  const [renderCustomFooter, setRenderCustomFooter] = useState(false)

  useEffect(() => {
    if (whiteLabelServiceSettings.length > 0) {
      const footerId = whiteLabelServiceSettings[0].custom.customFooterId;
      if (footerId && components.hasOwnProperty(footerId)) {
        setCustomFooterId(footerId)
        setRenderCustomFooter(true)
      }
    }
  }, [])

  //default top bar menu setting
  const [renderPrivacyPolicy, setRenderPrivacyPolicy] = useState(true)
  const [renderTermsOfUse, setRenderTermsOfUse] = useState(true)
  const [renderTermsOfServices, setRenderTermsOfServices] = useState(true)

  useEffect(() => {
    //determine white label top bar menu
    if (whiteLabelServiceSettings.length > 0) {

      //overwrite default setting
      let wbPrivacyPolicy = whiteLabelServiceSettings[0].custom.privacyPolicy
      if (wbPrivacyPolicy == null || wbPrivacyPolicy == undefined) {
        setRenderPrivacyPolicy(false)
      }

      let wbTermsOfUse = whiteLabelServiceSettings[0].custom.termsOfUse
      if (wbTermsOfUse == null || wbTermsOfUse == undefined) {
        setRenderTermsOfUse(false)
      }

      let wbTermsOfServices = whiteLabelServiceSettings[0].custom.termsOfServices
      if (wbTermsOfServices == null || wbTermsOfServices == undefined) {
        setRenderTermsOfServices(false)
      }
    }
  }, []);

  function RenderCustomFooter() {
    const Footer = components[customFooterId]
    return <Footer />
  }

  return (renderCustomFooter ? (RenderCustomFooter())
    : (
      <Box p={2} pr={8} style={{ overflowX: 'hidden' }}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md>
            {<Typography component='div' color='textSecondary' variant='caption'><Copyright /></Typography>}
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              {renderPrivacyPolicy && (
                <Grid item>
                  <PrivacyPolicy />
                </Grid>
              )}
              {renderTermsOfUse && (
                <Grid item>
                  <TermsOfUse />
                </Grid>
              )}
              {renderTermsOfServices && (
                <Grid item>
                  <TermsOfServices />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  )
}

export default CustomFooter
