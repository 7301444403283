import React, { useEffect, useState } from 'react'
import {
    Button,
    makeStyles
} from '@material-ui/core'
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import {
    useTranslation,
    useWhiteLabelServiceSettings
} from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { RedirectPage } from 'src/common/LayoutCommon';
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import { useServices, constants } from 'cng-web-lib'

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
    button: {
        color: theme.palette.common.white,
        fontWeight: 600,
        textTransform: 'none'
    }
}))

function Legacy(
    {
        defaultPagePath
    }
) {
    const history = useHistory()
    const classes = useStyles()
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const { fetchRecords } = useServices();
    var cutOffDate = "2024-09-03";
    const [enableLegacyButton, setEnableLegacyButton] = useState(false)

    useEffect(() => {
        getCutoffDate();
        getEnableLegacyButton();
    }, [])

    function getCutoffDate() {
        const onSuccess = (response) => {
            response.content.map((status) => {
                cutOffDate = status.code;
            })
        }

        fetchRecords.execute(
            NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
            {
                customData: { codeMType: constants.CodeMaintenanceType.CODE_MASTER },
                filters: [
                    {
                        field: 'active',
                        operator: 'equal',
                        value: 'true'
                    },
                    {
                        field: 'codeType',
                        operator: 'equal',
                        value: 'UPS_LEGACY_BTN_CUTOFF_DATE'
                    }
                ]
            },
            onSuccess,
            () => {
            }
        )
        return cutOffDate;
    }

    function getEnableLegacyButton() {
        const onSuccess = (response) => {
            response.content.map((status) => {
                console.log("status: " + JSON.stringify(status));
                if (status.code === 'true') {
                    setEnableLegacyButton(true)
                }
                console.log("enableLegacyButton: " + JSON.stringify(enableLegacyButton));
                console.log("enableLegacyButton flag: " + enableLegacyButton);

            })
        }

        fetchRecords.execute(
            NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
            {
                customData: { codeMType: constants.CodeMaintenanceType.CODE_MASTER },
                filters: [
                    {
                        field: 'active',
                        operator: 'equal',
                        value: 'true'
                    },
                    {
                        field: 'codeType',
                        operator: 'equal',
                        value: 'UPS_LEGACY_BTN_ENABLE'
                    }
                ]
            },
            onSuccess,
            () => {
            }
        )
        return enableLegacyButton;
    }

    function makeTranslatedTextsObject() {
        let aboutUs = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.ABOUT_US
        )

        return {
            aboutUs
        }
    }

    function redirectPage() {
        var coDateStr = getCutoffDate();
        console.log(coDateStr);
        var coDate = new Date(coDateStr + 'T04:00:00');
        let diff = new Date().getTime() - coDate.getTime();

        let legacyPath = ""

        if (diff > 0) { // Date passed
            legacyPath = "https://borderreadyca.ups.com"
        } else {
            legacyPath = "https://borderready.ups.com"
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/about-us' : defaultPagePath;
        RedirectPage(history, legacyPath, defaultPagePath, legacyPath)
    }
    return (
        enableLegacyButton && <Button className={classes.button} onClick={redirectPage}>
            Legacy System
        </Button>
    )
}

export default Legacy;