import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import TopBar from './TopBar'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingTop: 64
  }
}))

function RAFHomePageLayout({ children }) {
  const classes = useStyles()
  sessionStorage.setItem('homePagePath', '/na-mig/raf')

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = process.env.PUBLIC_URL + '/static/rafAssets/favicon-raf.ico'
  }, [])

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.content}>{children}</div>
      <Footer />
    </div>
  )
}

export default RAFHomePageLayout
