import {
  AppBar,
  Box,
  Hidden,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core'

import Logo from './Logo'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import classNames from 'classnames'
import { components } from 'cng-web-lib'
import AboutUs from '../CustomCngLayout/CustomTopBar/AboutUs'
import ContactUs from '../CustomCngLayout/CustomTopBar/ContactUs'
import UpdatePassword from 'src/layouts/CustomCngLayout/CustomTopBar/UpdatePassword'
const {
  button: { CngPrimaryButton }
} = components

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 64,
    background: "linear-gradient(270deg, rgba(0,133,125,1) 5%, rgba(0,167,157,1) 25%, rgba(0,189,177,1) 50%, rgba(255,255,255,1) 90%)",
    '& .MuiIconButton-root .MuiIconButton-label': {
      color: theme.palette.common.white
    },

    '& .MuiButton-root.ng-button-filled-primary': {
      backgroundColor: 'transparent',
      color: theme.palette.common.white,
      border: 'solid',
      borderRadius: '30px',
    },
    '& .MuiButton-root.ng-button-filled-primary:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    }
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}))

function TopBar({ className, ...rest }) {
  const classes = useStyles()

  return (
    <AppBar
      className={classNames(classes.root, className)}
      color='default'
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to='/'>
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden mdDown>
          <Typography variant='caption'>
            Version {process.env.REACT_APP_VERSION || '0.0.1'}
          </Typography>
        </Hidden>
        <Box flexGrow={1} />
        <ContactUs defaultPagePath={"gets-contact-us"} />
        <AboutUs/>
        <CngPrimaryButton
          className={classes.link}
          component={RouterLink}
          to='/login'
        >
          Login
        </CngPrimaryButton>
        <UpdatePassword defaultPagePath={"update-password"}/>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string
}

export default TopBar
