import React from 'react'
import { useWhiteLabelServiceSettings } from 'cng-web-lib'

function WhiteLabelLogo() {
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
  let logoPath = process.env.REACT_APP_LOGO_PUBLIC_PATH
  let isLogoCustom = false

  if (whiteLabelServiceSettings.length > 0 && whiteLabelServiceSettings[0].custom.logo != undefined) {
    logoPath = whiteLabelServiceSettings[0].custom.logo
    isLogoCustom = true
  }


  return (isLogoCustom ?
    (<img alt='Logo' src={`${process.env.PUBLIC_URL}${logoPath}`} style={{ height: 'auto', width: 'auto', maxHeight: '50px', maxWidth: '200px' }} />)
    :
    (<img alt='Logo' src={`${process.env.PUBLIC_URL}${logoPath}`} className={'logo-gets'} />)
  )
}

export default WhiteLabelLogo;