import React from 'react'

import {
  useWhiteLabelServiceSettings
} from 'cng-web-lib'

function WhiteLabelLogo() {
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
  let logoPath = process.env.REACT_APP_LOGO_PUBLIC_PATH

  if (whiteLabelServiceSettings.length > 0) {
    if(whiteLabelServiceSettings[0].custom.logo!=undefined){
      logoPath = whiteLabelServiceSettings[0].custom.logo
    }
  }

  return (
    <img
      alt='Logo'
      src={`${process.env.PUBLIC_URL}${logoPath}`}
      className={'logo-gets'}
    />
  )
}

export default WhiteLabelLogo;