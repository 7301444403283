import React, { useState, useEffect } from 'react'
import {
    useTranslation,
    useWhiteLabelServiceSettings
} from 'cng-web-lib'
import {
    Badge,
    IconButton,
    SvgIcon,
    Tooltip,
    makeStyles
} from '@material-ui/core'
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'

function Copyright() {

    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [copyRightState, setCopyrightState] = useState(false)

    function makeTranslatedTextsObject() {

        let copyright = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.COPYRIGHT
        )
        return {
            copyright
        }
    }

    useEffect(() => {
        let copyrightLabel = translatedTextsObject.copyright

        let wbCopyrightLabel;
        if (whiteLabelServiceSettings.length > 0) {
            wbCopyrightLabel = whiteLabelServiceSettings[0].custom.copyright

            if (wbCopyrightLabel != null && wbCopyrightLabel != undefined) {
                copyrightLabel = wbCopyrightLabel;
            }
        }

        if(copyrightLabel.length>0){
            copyrightLabel = copyrightLabel.replace(/{YYYY}/g, new Date().getFullYear());
        }
        setCopyrightState(copyrightLabel)

        {new Date().getFullYear()}

    }, []);

    return (
        <>
            <Badge>
                {copyRightState}
            </Badge>
        </>
    )
}

export default Copyright;