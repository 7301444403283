import React from 'react'
import { Button, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
    button: {
        fontSize: 16,
        fontWeight: 400,
        textTransform: 'none',
        transition: 'all 0.15s linear',
        borderRadius: 10,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#d25d13',
            boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08), 0px 6px 12px -2px rgba(0, 0, 0, 0.09), 0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
        }
    }
}))

function Webform({ targetUrl }) {
    const classes = useStyles()
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))

    function openWindow(newPath) {
        window.open(newPath, '_blank', 'noreferrer')
    }

    return (smUp && targetUrl) ? (<Button className={classes.button} onClick={() => openWindow(targetUrl)}>Webform</Button>) : <></>
}

export default Webform;