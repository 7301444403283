import React from 'react'
import { Badge, IconButton, SvgIcon, Tooltip, makeStyles } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import { useTranslation, useWhiteLabelServiceSettings } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { RedirectPage } from 'src/common/LayoutCommon';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
    button: {
        color: theme.palette.common.white,
        fontWeight: 600,
        textTransform: 'none'
    }
}))

function AboutUs(
    {
        defaultPagePath
    }
) {
    const history = useHistory()
    const classes = useStyles()
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")

    function makeTranslatedTextsObject() {
        let aboutUs = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.ABOUT_US
        )

        return {
            aboutUs
        }
    }

    function redirectPage() {
        let aboutUsPath = "https://globaletrade.services"

        let wbAboutUsPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbAboutUsPath = whiteLabelServiceSettings[0].custom.aboutUs
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/about-us' : defaultPagePath;
        RedirectPage(history, aboutUsPath, defaultPagePath, wbAboutUsPath)
    }
    return (
        <Tooltip title={translatedTextsObject.aboutUs}>
            <Badge color='primary' classes={{ badge: classes.badge }}>
                <IconButton onClick={redirectPage}>
                    <SvgIcon fontSize='small' >
                        <GroupIcon />
                    </SvgIcon>
                </IconButton>
            </Badge>
        </Tooltip>
    )
}

export default AboutUs;