import React from 'react'
import {
    Badge,
    IconButton,
    SvgIcon,
    Tooltip,
    makeStyles,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/BuildOutlined';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import {
    useTranslation,
    useWhiteLabelServiceSettings,
    WhiteLabelServiceSettingsContext
} from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { RedirectPage } from 'src/common/LayoutCommon';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
}))

function TradeResources({ defaultPagePath }) {
    const history = useHistory()
    const classes = useStyles()
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")

    function makeTranslatedTextsObject() {
        let tradeResources = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.TRADE_RESOURCES
        )

        return { tradeResources }
    }

    function redirectPage() {
        let tradeResourcesPath = ""
        let wbTradeResourcesPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbTradeResourcesPath = whiteLabelServiceSettings[0].custom.productInfo
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/trade-resources' : defaultPagePath;
        RedirectPage(history, tradeResourcesPath, defaultPagePath, wbTradeResourcesPath)
    }

    return (
        <Tooltip title={translatedTextsObject.tradeResources}>
            <Badge color='primary' classes={{ badge: classes.badge }}>
                <IconButton onClick={redirectPage}>
                    <SvgIcon fontSize='small' >
                        <InfoIcon />
                    </SvgIcon>
                </IconButton>
            </Badge>
        </Tooltip>
    )
}

export default TradeResources;