import {
  AppBar,
  Box,
  Container,
  Drawer,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

import Logo from './Logo'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { components } from 'cng-web-lib'
import ContactUs from 'src/layouts/CustomCngLayout/UpsCustomTopBar/ContactUs'
import ProductInfo from 'src/layouts/CustomCngLayout/UpsCustomTopBar/ProductInfo'
import TradeResources from 'src/layouts/CustomCngLayout/UpsCustomTopBar/TradeResources'
import FAQ from 'src/layouts/CustomCngLayout/UpsCustomTopBar/FAQ'
import clsx from 'clsx'

const {
  button: { CngButton }
} = components

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary
  },
  toolbar: {
    backgroundColor: theme.palette.common.white,
    height: 64,
    paddingLeft: 0,
    '& > .header-left': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  logo: {
    margin: '0 16px 0 0 !important'
  },
  link: {
    backgroundColor: '#ffc400',
    borderRadius: 999,
    boxShadow: 'none',
    color: '#121212',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.2,
    minHeight: 'unset',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd033',
      boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
        0px 6px 12px -2px rgba(0, 0, 0, 0.09),
        0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
    },
    '&:focus': {
      backgroundColor: '#ffa200'
    },
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}))

function TopBar({ className, ...rest }) {
  const classes = useStyles()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  
  return (
    <AppBar className={clsx(className, classes.appBar)} {...rest}>
      <Container>
        <Toolbar className={classes.toolbar}>
          <Box className='header-left'>
            <RouterLink to='/ups' style={{ display: 'flex' }}>
              <Logo className={classes.logo} />
            </RouterLink>
            {!xsDown && (
              <Typography
                color='inherit'
                variant='body2'
                style={{ fontWeight: 600, lineHeight: 1.2 }}
              >
                UPS
                <br />
                BorderReady
              </Typography>
            )}
          </Box>
          <Box display='flex' mx={4} style={{ gap: 16 }}>
            <ProductInfo defaultPagePath={'ups-product-info'} />
            <TradeResources defaultPagePath={'ups-trade-resources'} />
            <FAQ defaultPagePath={'ups-faq'} />
          </Box>

          <Box flexGrow={1} />
		  {!xsDown ? (
			  <Box>
				  <CngButton
					  className={classes.link}
					  component={RouterLink}
					  to='/login'
				  >
					  Login
				  </CngButton>
			  </Box>
		  ) : (
            <Drawer variant='permanent' anchor='bottom'>
              <Box display='flex' justifyContent='center' p={2}>
                <CngButton
                  className={classes.link}
                  component={RouterLink}
                  to='/login'
                >
                  Login
                </CngButton>
              </Box>
            </Drawer>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string
}

export default TopBar
