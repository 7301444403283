import React, { useState, useEffect,useContext } from 'react'
import { makeStyles, Grid, Snackbar, SnackbarContent, Typography, IconButton } from '@material-ui/core'
import { useServices, useTranslation, components } from 'cng-web-lib';
import AnnouncementApiUrls from 'src/apiUrls/AnnouncementApiUrls'
import Namespace from 'src/constants/locale/Namespace'
import AnnouncementKeys from 'src/constants/locale/key/Announcement'
import useFileForUser from 'src/hooks/useFileForUser'
import UserProfileApiUrls from 'src/apiUrls/UserProfileApiUrls'
import { Close as CloseIcon } from '@material-ui/icons'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import { Html5Entities } from 'html-entities';
import FileForUserContext from 'src/contexts/FileForUserContext'

const {
    CngDialog,
    button: { CngPrimaryButton },
} = components

const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK'
}

const useStyles = makeStyles((theme) => ({
    center: {
        "margin-left": "auto",
        "margin-right": "auto"
    },
    topScrollPaper: {
        alignItems: 'flex-start',
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    sbContent: {
        ...(theme.name === THEMES.LIGHT
            ? {
                color: "#000",
                backgroundColor: theme.palette.background.othersTopbar,
                minWidth: "600px"
            } : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                backgroundColor: theme.palette.background.othersTopbar,
                color: theme.palette.primary.contrastText,
                minWidth: "600px"
            }
            : {})

    },
    sbMessage: {
        width: "100%"
    },
    fileForUserLabel: {
        'font-weight': 'bold',
        color: 'red'
    }
}))

const htmlEntities = new Html5Entities();

function Announcement() {

    const classes = useStyles()
    const { translate } = useTranslation([Namespace.ANNOUNCEMENT, Namespace.HOME_PAGE])
    const [openDialog, setOpenDialog] = useState(false)
    const [message, setMessage] = useState("")
    const { securedSendRequest } = useServices();
    const translatedTextsObject = makeTranslatedTextsObject()
    const fileForUserValue = useFileForUser()
    const [dialogTitle, setDialogTitle] = useState(false)

    const [fileForUserByAdmin, setFileForUserByAdmin] = useState(false)
    let {fileForUserAdditionalValue} = useContext(FileForUserContext)

    function makeTranslatedTextsObject() {
        let portalAnnouncement = translate(
            Namespace.ANNOUNCEMENT,
            AnnouncementKeys.PORTAL_ANNOUNCEMENT
        )

        let closeButton = translate(
            Namespace.ANNOUNCEMENT,
            AnnouncementKeys.CLOSE_BUTTON
        )

        let specialInstruction = translate(
            Namespace.ANNOUNCEMENT,
            AnnouncementKeys.SPECIAL_INSTRUCTION
        )

        let fileForUser = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.FILE_FOR_USER
        )

        return {
            portalAnnouncement,
            closeButton,
            specialInstruction,
            fileForUser
        }
    }

    function onClose() {
        setOpenDialog(false)
    }

    useEffect(() => {

        setOpenDialog(false)
        let fileForUserByAdmin = sessionStorage.getItem("fileForUserByAdmin")

        if(fileForUserByAdmin!=null && fileForUserByAdmin!=undefined){
            setFileForUserByAdmin(fileForUserByAdmin)
        }else{
            setFileForUserByAdmin(false)
        }

        if (fileForUserValue != null && fileForUserValue != undefined
            && fileForUserByAdmin != null && fileForUserByAdmin != undefined
            && fileForUserByAdmin
        ) {
            let inputFileForUserDetails = {
                fileForUserId: fileForUserValue.fileForUserId,
                fileForUserPartyId: fileForUserValue.fileForUserPartyId,
                fileForUserLoginId: fileForUserValue.fileForUserLoginId
            }

            let config = {}
            //get special instruction from user profile

            securedSendRequest.execute(
                'POST',
                UserProfileApiUrls.GET,
                inputFileForUserDetails,
                (response) => {
                    let data = response.data
                    let errorMessages = data.errorMessages
                    console.log("data", data)
                    if (response.status == 200 && data != null && errorMessages == null) {

                        if (data.userSpecialInstructions != null && data.userSpecialInstructions != undefined && data.userSpecialInstructions.length > 0) {
                            let userSpecialInstruction = data.userSpecialInstructions

                            let finalHTML = "";
                            for (const details of userSpecialInstruction) {
                                finalHTML += "<div>";
                                finalHTML += details.specialInstruction;
                                finalHTML += "</div>";
                                finalHTML += "<br/><hr>"
                            }

                            setMessage(finalHTML)
                            setDialogTitle(translatedTextsObject.specialInstruction)
                            setOpenDialog(true)
                        }
                    }


                },
                (error) => {
                    //setLoadingState(false)
                    //showNotification("error", translatedTextsObject.errorMessageRetrieveData)
                    console.log(error);
                },
                null,
                config,
                null
            );
        } else {
            setOpenDialog(false)
        }

    }, [fileForUserValue])

    useEffect(() => {

        if (fileForUserValue != null && fileForUserValue != undefined 
            && fileForUserByAdmin != null && fileForUserByAdmin != undefined && !fileForUserByAdmin) {
            function onSuccess(response) {
                let data = response.data
                let errorMessages = data.errorMessages
                if (response.status == 200 && data != null && errorMessages == null) {

                    if (data.length > 0) {

                        let finalHTML = "";
                        for (const details of data) {
                            finalHTML += "<div>";
                            finalHTML += details.content;
                            finalHTML += "</div>";
                            finalHTML += "<br/><hr>"
                        }

                        setMessage(htmlEntities.decode(finalHTML))
                        setDialogTitle(translatedTextsObject.portalAnnouncement)
                        setOpenDialog(true)
                    }

                }
            }

            function onError(error) {
                console.log("Failed to retrieve Announcement", error)
            }

            function onComplete() { }
            const config = {}
            const formData = {}

            securedSendRequest.execute('POST', AnnouncementApiUrls.GET_ANNOUNCEMENT_BY_GROUP, formData, onSuccess, onError, onComplete, config, null);
        }

    }, []);
    return (
        <>
            {fileForUserByAdmin == null || fileForUserByAdmin == undefined || !fileForUserByAdmin &&
                <CngDialog
                    scroll="paper"
                    classes={{
                        scrollPaper: classes.topScrollPaper,
                        paperScrollBody: classes.topPaperScrollBody,
                    }}
                    fullWidth
                    maxWidth='md'
                    customDialogContent={
                        <div style={{ padding: '10px' }}
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    }
                    dialogTitle={dialogTitle}
                    open={openDialog}
                    onClose={onClose}
                    dialogAction={
                        <>
                            <CngPrimaryButton className={classes.center} onClick={onClose} >
                                {translatedTextsObject.closeButton}
                            </CngPrimaryButton>
                        </>
                    }
                />
            }
            {fileForUserValue != null && fileForUserValue != undefined && fileForUserByAdmin != null && fileForUserByAdmin != undefined && fileForUserByAdmin &&
                <Snackbar
                    open={openDialog}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <SnackbarContent
                        classes={{
                            root: classes.sbContent,
                            message: classes.sbMessage
                        }}
                        message={
                            <>
                                <Grid>
                                    <Grid container alignItems='center'>
                                        <Grid item xs={11}>
                                            <Typography variant="h5" >
                                                <b>
                                                    <Grid container>
                                                        <Grid item>
                                                            {translatedTextsObject.specialInstruction}
                                                        </Grid>(
                                                        <Grid item classes={{ root: classes.fileForUserLabel }} >
                                                            {translatedTextsObject.fileForUser}: {fileForUserValue.fileForUserLoginId} - {fileForUserAdditionalValue.partyName}
                                                        </Grid>
                                                        )
                                                    </Grid>
                                                </b>
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={1}>
                                            <Grid container justify='flex-end'>
                                                <Grid item>
                                                    <IconButton size='small' onClick={onClose}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Typography color='initial' variant='body2'>
                                        <div style={{ padding: '10px' }}
                                            dangerouslySetInnerHTML={{ __html: message }}
                                        />
                                    </Typography>
                                </Grid>
                            </>
                        }
                    ></SnackbarContent>
                </Snackbar>
            }
        </>
    )
}

export default Announcement