import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import {
  AppBar, Container, Box, Toolbar, Hidden, Drawer, Tooltip, SvgIcon, IconButton,
  Button, List, ListItem, ListItemIcon, ListItemText, Divider, makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MenuIcon from '@material-ui/icons/Menu'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import Logo from './Logo'
import ProductInfo from 'src/layouts/CustomCngLayout/RafCustomTopBar/ProductInfo'
import ContactUs from 'src/layouts/CustomCngLayout/RafCustomTopBar/ContactUs'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const { button: { CngButton } } = components

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'linear-gradient(270deg, #444444 5%, 25%, #c0c0c0 50%, rgba(255,255,255,1) 70%)',
    color: theme.palette.text.primary
  },
  toolbar: {
    height: 64,
    paddingLeft: 0,
    '& > .header-left': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  logo: {
    marginRight: '0 16px 0 0 !important'
  },
  login: {
    backgroundColor: '#fff',
    borderColor: '#c09b30',
    borderWidth: 2,
    borderRadius: 999,
    borderStyle: 'solid',
    boxShadow: 'none',
    color: '#c09b30',
    fontSize: 16,
    lineHeight: 1.2,
    minHeight: 'unset',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#c09b30',
      boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
        0px 6px 12px -2px rgba(0, 0, 0, 0.09),
        0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
    }
  },
  hamburgerMenu: {
    left: -10,
    position: 'relative',
    '& svg': {
      color: theme.palette.common.white
    }
  }
}))

function TopBar({ className, ...rest }) {
  const classes = useStyles()
  const history = useHistory()

  const [hamIcon, setHamIcon] = useState(false)

  const sideBar = (
    <Box p={2} style={{ background: '#efefef', width: 250, height: '100%' }} onClick={() => setHamIcon(false)}>
      <List>
        <ListItem disablePadding>
          <Button onClick={() => history.push('raf')}>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-house" size='lg' /></ListItemIcon>
            <ListItemText primary='Home' />
          </Button>
        </ListItem>

        <ListItem disablePadding>
          <Button onClick={() => history.push('raf-product-info')}>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-circle-info" size='lg' /></ListItemIcon>
            <ListItemText primary='Product Info' />
          </Button>
        </ListItem>

        <ListItem disablePadding>
          <Button onClick={() => history.push('raf-contact-us')}>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-phone" size='lg' /></ListItemIcon>
            <ListItemText primary='Contact Us' />
          </Button>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem disablePadding>
          <Button component={RouterLink} to='/login'>
            <ListItemIcon><FontAwesomeIcon icon="fa-solid fa-right-to-bracket" size='lg' /></ListItemIcon>
            <ListItemText primary='Login' />
          </Button>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <AppBar className={clsx(className, classes.appBar)} {...rest}>
      <Container>
        <Toolbar className={classes.toolbar}>
          <Box className='header-left'>
            <RouterLink to='/raf'>
              <Logo className={classes.logo} style={{ display: 'flex' }} />
            </RouterLink>
          </Box>

          <Hidden smDown>

            <Box display='flex' mx={4} marginTop={'10px'} style={{ gap: 16 }}>
              <ProductInfo defaultPagePath={'raf-product-info'} />
              <ContactUs defaultPagePath={'raf-contact-us'} />
            </Box>

            <Box flexGrow={1} />

            <CngButton className={classes.login} component={RouterLink} to='/login'>Login</CngButton>

          </Hidden>

          <Hidden mdUp>
            <Box flexGrow={1} />
            <Tooltip title='Toggle Sidebar' className={classes.hamburgerMenu}>
              <IconButton
                className={clsx(classes.menuButton, hamIcon ? 'state-expanded' : 'state-collapsed')}
                color='inherit' onClick={() => setHamIcon(true)}
              >
                <SvgIcon>{hamIcon ? <MenuIcon /> : <MenuOpenIcon />}</SvgIcon>
              </IconButton>
            </Tooltip>
          </Hidden>
        </Toolbar>
        <Drawer anchor='right' open={hamIcon} onClose={() => setHamIcon(false)}>{sideBar}</Drawer>
      </Container>
    </AppBar>
  )
}

TopBar.propTypes = { className: PropTypes.string }

export default TopBar
