import React, { useState, useEffect } from 'react'
import ZohoLiveChat from 'src/views/userprofile/ZohoLiveChat'
import { useWhiteLabelServiceSettings } from 'cng-web-lib'

function CustomLiveChat() {
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
  const [customChatButton, setCustomChatButton] = useState(null);
  useEffect(() => {
    if (whiteLabelServiceSettings.length > 0) {
      const customChatButton = whiteLabelServiceSettings[0].custom.customChatButton;
      setCustomChatButton(customChatButton == undefined ? 'true' : customChatButton);
    } else {
      setCustomChatButton(true)
    }
  }, [])

  if (customChatButton !== null) {
    if (customChatButton == 'false') {
      return ""
    } else {
      return <ZohoLiveChat isChatOpen='show' />
    }
  }
  return ""
}

export default CustomLiveChat
