import React from 'react'
import {
    Badge,
    IconButton,
    SvgIcon,
    Tooltip,
    makeStyles,
} from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone';
import Namespace from 'src/constants/locale/Namespace'
import HomePageKeys from 'src/constants/locale/key/HomePage'
import {
    useTranslation,
    useWhiteLabelServiceSettings
} from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import { RedirectPage } from 'src/common/LayoutCommon';

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    },
}))

function ContactUs({
    defaultPagePath
}
) {
    const classes = useStyles()
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const { translate } = useTranslation(Namespace.HOME_PAGE)
    const translatedTextsObject = makeTranslatedTextsObject()
    const history = useHistory()

    function makeTranslatedTextsObject() {
        let contactUs = translate(
            Namespace.HOME_PAGE,
            HomePageKeys.CONTACT_US
        )

        return {
            contactUs
        }
    }

    function redirectPage() {
        let contactUsPath = '/shared/contact-us'

        let wbContactUsPath;
        if (whiteLabelServiceSettings.length > 0) {
            wbContactUsPath = whiteLabelServiceSettings[0].custom.contactUs
        }

        defaultPagePath = (defaultPagePath == null || defaultPagePath == undefined) ? '/shared/contact-us' : defaultPagePath;
        RedirectPage(history, contactUsPath,defaultPagePath, wbContactUsPath)
    }

    return (
      <Tooltip title={translatedTextsObject.contactUs}>
        <Badge color='primary' classes={{ badge: classes.badge }}>
          <IconButton onClick={redirectPage}>
            <SvgIcon fontSize='small'>
              <PhoneIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
    )
}

export default ContactUs;