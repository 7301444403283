import Footer, { ExtendedFooter } from './Footer'

import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import TopBar from './TopBar'
import { makeStyles } from '@material-ui/core'
import { routes } from 'cng-web-lib'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ebebeb',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

function HomePageLayout({ children }) {
  const classes = useStyles()
  const { CngPublicRoute } = routes

  const cngPublicRoute = new CngPublicRoute()

  cngPublicRoute.addConfigurations([

  ]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = process.env.PUBLIC_URL + '/favicon-gets.ico';
  }, []);

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
            <Footer>
              <ExtendedFooter />
            </Footer>
          </div>
        </div>
      </div>
    </div>
  )
}

HomePageLayout.propTypes = {
  children: PropTypes.any
}

export default HomePageLayout
