import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {
  faArrowUpRightFromSquare,
  faDownload,
  faGlobe,
  faMapMarkerAlt,
  faMessage,
  faPhoneAlt
} from '@fortawesome/pro-light-svg-icons'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#5f5753',
    color: 'white',
    paddingTop: 32,
    paddingBottom: 32,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 68 + 32,
    }
  },
  divider: {
    backgroundColor: theme.palette.common.white
  }
}))

function ExtendedFooter() {
  const history = useHistory()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()

  function redirectPage(newPath) {
    history.push(newPath, '_blank', 'noreferrer')
  }

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }
  
  function downloadFile(filePath) {
    window.open(filePath, '_blank', 'noreferrer')
  }

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box mb={1}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    About Us
                  </Typography>
                </Box>
                <List disablePadding>
                  <ListItem
                    button
                    component='a'
                    dense
                    disableGutters
                    onClick={() =>
                      openWindow('https://about.ups.com/us/en/home.html')
                    }
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        color='#ffffff'
                        fixedWidth
                        icon={faGlobe}
                      />
                    </ListItemIcon>
                    <ListItemText primary='Who We Are' />
                  </ListItem>
                  <ListItem
                    button
                    component='a'
                    dense
                    disableGutters
                    onClick={() => redirectPage('ups-faq')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        color='#ffffff'
                        fixedWidth
                        icon={faMessage}
                      />
                    </ListItemIcon>
                    <ListItemText primary='FAQ' />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={1}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Quick Links
                  </Typography>
                </Box>
                <List disablePadding>
                  <ListItem
                    button
                    component='a'
                    dense
                    disableGutters
                    onClick={() => openWindow('http://www.ups.com/')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        color='#ffffff'
                        fixedWidth
                        icon={faArrowUpRightFromSquare}
                      />
                    </ListItemIcon>
                    <ListItemText primary='UPS.com' />
                  </ListItem>
                </List>
                <List disablePadding>
                  <ListItem
                    button
                    component='a'
                    dense
                    disableGutters
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/UPS Next-Gen HWY Quick Guide_ V0.4_rv.docx')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        color='#ffffff'
                        fixedWidth
                        icon={faDownload}
                      />
                    </ListItemIcon>
                    <ListItemText primary='Next-Gen HWY Quick Guide' />
                  </ListItem>
                </List>
                <List disablePadding>
                  <ListItem
                    button
                    component='a'
                    dense
                    disableGutters
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/NANG_NBCI_User_Guide__V0_4_rv_with_trademark_comments.docx')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        color='#ffffff'
                        fixedWidth
                        icon={faDownload}
                      />
                    </ListItemIcon>
                    <ListItemText primary='NBCI User Guide' />
                  </ListItem>
                </List>
                <List disablePadding>
                  <ListItem
                    button
                    component='a'
                    dense
                    disableGutters
                    onClick={() => downloadFile('/na-mig/static/page/ups/documents/NANG_SBCI_User_Guide__V0_4_rv_with_trademark_comments.docx')}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        color='#ffffff'
                        fixedWidth
                        icon={faDownload}
                      />
                    </ListItemIcon>
                    <ListItemText primary='SBCI User Guide' />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justify={mdUp ? 'flex-end' : 'flex-start'}>
              <Grid item xs='auto'>
                <Box mb={1}>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Office Location
                  </Typography>
                </Box>
                <List disablePadding>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        color='#ffffff'
                        fixedWidth
                        icon={faMapMarkerAlt}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary='Corporate Headquarters UPS'
                      secondary={
                        <Typography
                          component='div'
                          variant='caption'
                          style={{ color: 'white', marginTop: 4 }}
                        >
                          <span>55 Glenlake Parkway, NE</span>
                          <br />
                          <span>Atlanta, GA 30328</span>
                        </Typography>
                      }
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        color='#ffffff'
                        fixedWidth
                        icon={faPhoneAlt}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary='1-404-828-6000'
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='space-between' spacing={3}>
              <Grid item xs={12} md='auto'>
                <Typography variant='body2' style={{ color: 'white' }}>
                  Copyright &copy; 1994-2013 United Parcel Service of America,
                  Inc. All rights reserved.
                </Typography>
              </Grid>
              <Grid item xs={12} md='auto'>
                <Link
                  underline='none'
                  component={RouterLink}
                  to='/ups-end-user-web-agreement'
                  style={{ color: 'white' }}
                >
                  Terms of Use
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ExtendedFooter
