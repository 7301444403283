const PRODUCT_MASTER_PATH = `/shared/product-master`
const PARTY_MASTER_PATH = `/shared/party-master`
const ARRIVALMESSAGE_PATH = `/shared/arrivalmessage`
const ARRIVAL_RESPONSE_PATH = `/shared/arrivalmessage/response`
const ACIOCEAN_PATH = `/aci-ocean/a6`
const UPLOAD_RESPONSE_PATH = `/shared/upload`
const USER_PROFILE_PATH = `/shared/profile`
const COMMUNICATION_UTIL_PATH = `/shared/communication`
const BILLING_CONFIG_PATH = `/shared/billing-config`
const ACI_AIR_ACR_PATH = `/aci-air/acr`
const ACI_AIR_SSR_PATH = `/aci-air/ssr`
const ACI_AIR_S10_PATH = `/aci-air/s10`
const ACI_AIR_ARRIVAL_MESSAGE_PATH = `/aci-air/airarrivalmessage`
const CONVEYANCE_PATH = `/aci-air/conveyance`
const AIR_ARRIVAL_PATH = `/shared/air-arrivalmessage`
const A6A_PATH = `/aci-ocean/a6a`
const ACI_OCN_ARRIVAL_MESSAGE_PATH = `/aci-ocean/arrivalmessage`
const DOC_GEN_PURCHASE_ORDER_PATH = `/doc-gen/purchase-order`
const STATUS_MESSAGE_PATH = `/doc-gen/statusmessage`
const DOC_GEN_UPLOAD_PATH = `/doc-gen/docgen`
const H2H_SUB_FAILS_PATH = `/doc-gen/h2h-sub-fails`
const FILE_USER_PATH = `/shared/file/user`
const FILE_FOR_USER_PATH = `/shared/file-for-user`
const INVOICE_PATH = `/doc-gen/invoice`
const FLIGHT_MATRIX_PATH = `/aci-air/flight-matrix`
const VALIDATION_ERROR_LOG_PATH = `/aci-air/validation-error-log`
const H_S_CODE_PATH = `/code-maintenance/hscode`
const DBADMIN_ACIAIR_PATH = `/shared/aciair-dbadmin`
const SBCI_INVOICE_PATH = `/sbci/sbci-invoice`
const ACIHWY_MANIFEST_PATH = `/aci-highway/manifest`
const SBCI_PRODUCT_MASTER_PATH = `/sbci/productmaster`
const SBCI_MANIFEST_PATH = `/sbci/manifest`
const SBCI_FILE_UPLOAD_HISTORY_PATH = `/sbci/fileuploadhistory`
const SBCI_UPLOAD_PATH = `/shared/sbci` //This menu is not being used
const EQUIPMENT_MASTER_PATH = `/shared/equipment-master`
const CONVEYANCE_MASTER_PATH = `/shared/conveyance-master`
const ACI_HW_STAT_QRY_PATH = `/aci-highway/status-query`
const CREW_MASTER_PATH = `/shared/crew-master`
const HOME_PATH = `/home`
const US_PRODUCT_MASTER_PATH = `/nbci/us-product-master`
const US_DECLARATIONS_PATH = `/nbci/us-declarations`
const ACI_HW_CARGO_PATH = `/aci-highway/cargo`
const ACI_HW_D4_NOTICE_PATH = `/aci-highway/d4Notices`
const ACI_HW_RELEASE_NOTICE_PATH = `/aci-highway/release-notice`
const ACI_HW_INBOND_ARRIVAL_PATH = `/aci-highway/inbond`
const ACI_HIGHWAY_ARRIVAL_PATH = `/shared/aci-highway-arrivalmessage`
const ACE_HIGHWAY_SHIPMENT_PATH = `/ace-highway/shipment`
const ACE_HIGHWAY_MANIFEST_PATH = `/ace-highway/manifest`
const ACE_HIGHWAY_INBOND_ARRIVAL_PATH = `/ace-highway/inbond`
const MANIFEST_FAX_TRACKER_PATH = `/shared/fax-tracker`
const ABI_INBOND_7512_PATH = `/ace-highway/inbond-7512`
const ABI_INBOND_EVENT_PATH = `/ace-highway/inbond-event`
const EHBL_MANIFEST_PATH = `/ehbl/manifest`
const EHBL_HOUSE_BILL_PATH = `/ehbl/house-bill`
const EHBL_CLOSE_MSG_PATH = `/ehbl/close-msg`
const EHBL_MANIFEST_FORWARD_PATH = `/ehbl/manifest-forward`
const DASHBOARD_SUMMARY_PATH = `/dashboard/summary`
const DB_ADMIN_STATUS_CONFIG_PATH = `/db-admin/status-config`
const NBCI_US_CA_PARTY_MASTER_PATH = `/nbci/usca-party-master`
const N_B_C_I_CANADA_INVOICE_PATH = `/nbci/n-b-c-i-canada-invoice`
const NBCI_US_INVOICE_PATH = `/nbci/nbci-us-invoice`
const NBCI_GENERAL_PATH = `/nbci`
const HOME_PAGE_ABOUT_US = `/shared/about-us`
const HOME_PAGE_CONTACT_US = `/shared/contact-us`
const HOME_PAGE_PRODUCT_INFO = `/shared/product-info`
const HOME_PAGE_TRADE_RESOURCES = `/shared/trade-resources`
const HOME_PAGE_FAQ = `/shared/faq`
const HOME_PAGE_PRIVACY_POLICY = `/shared/privacy-policy`
const HOME_PAGE_TERMS_OF_USE = `/shared/terms-of-use`
const HOME_PAGE_TERMS_OF_SERVICES = `/shared/terms-of-services`
const ANNOUNCEMENT_PATH = `/shared/announcement`
const WACM_MF_NOTICE_PATH = `/aci-highway/manifest-forward`
const EHBL_D4_NOTICE_PATH = `/ehbl/d4Notices`
const WACM_D4_NOTICE_PATH = `/aci-highway/wacmD4Notices`
const ACEISF_10_PATH = `/ace-isf/isf10`
const ACEISF_5_PATH = `/ace-isf/isf5`
const ACE_OCN_MANIFEST_PATH = `/ace-ocean/manifest`
const ACE_OCN_BILLOFLADING_PATH = `/ace-ocean/billoflading`
const ACE_OCN_VESSEL_MASTER_PATH = `/ace-ocean/vessel-master`
const ACE_OCN_VESSEL_ARRIVAL_PATH = `/ace-ocean/vessel-arrival`
const ACE_OCN_VESSEL_DEPARTURE_PATH = `/ace-ocean/vessel-departure`
const PARTY_CONFIG_PATH = `/db-admin/party-config`
const AES_SHIPMENT_PATH = `/aes/shipment`
const AES_PARTY_SCREENING_PATH = `/aes/party-screening`
const AES_PRODUCT_MASTER_PATH = `/aes/product-master`
const FDAPN_PATH = `/fda-pn`
const FDAPN_PRODUCTMASTER_PATH = `/fda-pn/product-master`

const pathMap = {
  // acihighway manifest
  ACIHWY_MANIFEST: ACIHWY_MANIFEST_PATH,
  ACIHWY_MANIFEST_LIST_VIEW: ACIHWY_MANIFEST_PATH,
  ACIHWY_MANIFEST_ADD_VIEW: `${ACIHWY_MANIFEST_PATH}/add`,
  ACIHWY_MANIFEST_EDIT_VIEW: `${ACIHWY_MANIFEST_PATH}/edit/:id`,
  ACIHWY_MANIFEST_DETAILS_VIEW: `${ACIHWY_MANIFEST_PATH}/view/:id`,
  // dbadmin aciair
  DBADMIN_ACIAIR: DBADMIN_ACIAIR_PATH,
  DBADMIN_ACIAIR_ADD_VIEW: `${DBADMIN_ACIAIR_PATH}/add`,

  //validation error UI

  VALIDATION_ERROR_LOG: VALIDATION_ERROR_LOG_PATH,
  VALIDATION_ERROR_LOG_LIST_VIEW: VALIDATION_ERROR_LOG_PATH,


  //flight matrix

  FLIGHT_MATRIX: FLIGHT_MATRIX_PATH,
  FLIGHT_MATRIX_LIST_VIEW: FLIGHT_MATRIX_PATH,
  FLIGHT_MATRIX_ADD_VIEW: `${FLIGHT_MATRIX_PATH}/add`,
  FLIGHT_MATRIX_EDIT_VIEW: `${FLIGHT_MATRIX_PATH}/edit/:id`,
  FLIGHT_MATRIX_DETAILS_VIEW: `${FLIGHT_MATRIX_PATH}/view/:id`,

  //product-master
  PRODUCT_MASTER: PRODUCT_MASTER_PATH,
  PRODUCT_MASTER_LIST_VIEW: PRODUCT_MASTER_PATH,
  PRODUCT_MASTER_ADD_VIEW: `${PRODUCT_MASTER_PATH}/add`,
  PRODUCT_MASTER_EDIT_VIEW: `${PRODUCT_MASTER_PATH}/edit/:id`,
  PRODUCT_MASTER_DETAILS_VIEW: `${PRODUCT_MASTER_PATH}/view/:id`,
  //party-master
  PARTY_MASTER: PARTY_MASTER_PATH,
  PARTY_MASTER_LIST_VIEW: PARTY_MASTER_PATH,
  PARTY_MASTER_ADD_VIEW: `${PARTY_MASTER_PATH}/add`,
  PARTY_MASTER_EDIT_VIEW: `${PARTY_MASTER_PATH}/edit/:id`,
  PARTY_MASTER_DETAILS_VIEW: `${PARTY_MASTER_PATH}/view/:id`,
  PARTY_MASTER_UPLOAD_LIST_VIEW: `${PARTY_MASTER_PATH}/upload`,

  //arrival-message
  ARRIVALMESSAGE: ARRIVALMESSAGE_PATH,
  ARRIVALMESSAGE_LIST_VIEW: ARRIVALMESSAGE_PATH,
  ARRIVALMESSAGE_ADD_VIEW: `${ARRIVALMESSAGE_PATH}/add`,
  ARRIVALMESSAGE_EDIT_VIEW: `${ARRIVALMESSAGE_PATH}/edit/:id`,
  ARRIVALMESSAGE_DETAILS_VIEW: `${ARRIVALMESSAGE_PATH}/view/:id`,

  //air arrival

  AIR_ARRIVAL: AIR_ARRIVAL_PATH,
  AIR_ARRIVAL_LIST_VIEW: AIR_ARRIVAL_PATH,
  AIR_ARRIVAL_ADD_VIEW: `${AIR_ARRIVAL_PATH}/add`,
  AIR_ARRIVAL_EDIT_VIEW: `${AIR_ARRIVAL_PATH}/edit/:id`,
  AIR_ARRIVAL_DETAILS_VIEW: `${AIR_ARRIVAL_PATH}/view/:id`,

  //arrival-response 

  ARRIVAL_RESPONSE: ARRIVAL_RESPONSE_PATH,
  ARRIVAL_RESPONSE_LIST_VIEW: ARRIVAL_RESPONSE_PATH,

  //upload -reponse path

  UPLOAD_RESPONSE: UPLOAD_RESPONSE_PATH,
  UPLOAD_RESPONSE_LIST_VIEW: UPLOAD_RESPONSE_PATH,

  //USER-PROFILE
  USER_PROFILE: USER_PROFILE_PATH,
  USER_PROFILE_LIST_VIEW: USER_PROFILE_PATH,

  //Communication
  COMMUNICATION_UTIL: COMMUNICATION_UTIL_PATH,

  //Billing Config
  BILLING_CONFIG_LIST_VIEW: BILLING_CONFIG_PATH,

  //ACI-OCEAN A6

  ACIOCEAN: ACIOCEAN_PATH,
  ACIOCEAN_LIST_VIEW: ACIOCEAN_PATH,
  ACIOCEAN_ADD_VIEW: `${ACIOCEAN_PATH}/add`,
  ACIOCEAN_EDIT_VIEW: `${ACIOCEAN_PATH}/edit/:id`,
  ACIOCEAN_DETAILS_VIEW: `${ACIOCEAN_PATH}/view/:id`,

  A6A: A6A_PATH,
  A6A_LIST_VIEW: A6A_PATH,
  A6A_ADD_VIEW: `${A6A_PATH}/add`,
  A6A_EDIT_VIEW: `${A6A_PATH}/edit/:id`,
  A6A_DETAILS_VIEW: `${A6A_PATH}/view/:id`,

  //aci ocean arrival-message
  ACI_OCN_ARRIVAL_MESSAGE: ACI_OCN_ARRIVAL_MESSAGE_PATH,
  ACI_OCN_ARRIVAL_MESSAGE_LIST_VIEW: ACI_OCN_ARRIVAL_MESSAGE_PATH,
  ACI_OCN_ARRIVAL_MESSAGE_ADD_VIEW: `${ACI_OCN_ARRIVAL_MESSAGE_PATH}/add`,
  ACI_OCN_ARRIVAL_MESSAGE_EDIT_VIEW: `${ACI_OCN_ARRIVAL_MESSAGE_PATH}/edit/:id`,
  ACI_OCN_ARRIVAL_MESSAGE_DETAILS_VIEW: `${ACI_OCN_ARRIVAL_MESSAGE_PATH}/view/:id`,

  //ACI-AIR ACR
  ACI_AIR_ACR: ACI_AIR_ACR_PATH,
  ACI_AIR_ACR_LIST_VIEW: ACI_AIR_ACR_PATH,
  ACI_AIR_ACR_ADD_VIEW: `${ACI_AIR_ACR_PATH}/add`,
  ACI_AIR_ACR_EDIT_VIEW: `${ACI_AIR_ACR_PATH}/edit/:id`,
  ACI_AIR_ACR_DETAILS_VIEW: `${ACI_AIR_ACR_PATH}/view/:id`,

  //ACI-AIR SSR
  ACI_AIR_SSR: ACI_AIR_SSR_PATH,
  ACI_AIR_SSR_LIST_VIEW: ACI_AIR_SSR_PATH,
  ACI_AIR_SSR_ADD_VIEW: `${ACI_AIR_SSR_PATH}/add`,
  ACI_AIR_SSR_EDIT_VIEW: `${ACI_AIR_SSR_PATH}/edit/:id`,
  ACI_AIR_SSR_DETAILS_VIEW: `${ACI_AIR_SSR_PATH}/view/:id`,

  //ACI-AIR S10
  ACI_AIR_S10: ACI_AIR_S10_PATH,
  ACI_AIR_S10_LIST_VIEW: ACI_AIR_S10_PATH,
  ACI_AIR_S10_ADD_VIEW: `${ACI_AIR_S10_PATH}/add`,
  ACI_AIR_S10_EDIT_VIEW: `${ACI_AIR_S10_PATH}/edit/:id`,
  ACI_AIR_S10_DETAILS_VIEW: `${ACI_AIR_S10_PATH}/view/:id`,


  //ACI-AIR arrival-message
  ACI_AIR_ARRIVAL_MESSAGE: ACI_AIR_ARRIVAL_MESSAGE_PATH,
  ACI_AIR_ARRIVAL_MESSAGE_LIST_VIEW: ACI_AIR_ARRIVAL_MESSAGE_PATH,
  ACI_AIR_ARRIVAL_MESSAGE_ADD_VIEW: `${ACI_AIR_ARRIVAL_MESSAGE_PATH}/add`,
  ACI_AIR_ARRIVAL_MESSAGE_EDIT_VIEW: `${ACI_AIR_ARRIVAL_MESSAGE_PATH}/edit/:id`,
  ACI_AIR_ARRIVAL_MESSAGE_DETAILS_VIEW: `${ACI_AIR_ARRIVAL_MESSAGE_PATH}/view/:id`,


  //conveyance aci-air

  CONVEYANCE: CONVEYANCE_PATH,
  CONVEYANCE_LIST_VIEW: CONVEYANCE_PATH,
  CONVEYANCE_ADD_VIEW: `${CONVEYANCE_PATH}/add`,
  CONVEYANCE_EDIT_VIEW: `${CONVEYANCE_PATH}/edit/:id`,
  CONVEYANCE_DETAILS_VIEW: `${CONVEYANCE_PATH}/view/:id`,

  //doc_gen
  DOC_GEN_PURCHASE_ORDER: DOC_GEN_PURCHASE_ORDER_PATH,
  DOC_GEN_PURCHASE_ORDER_LIST_VIEW: DOC_GEN_PURCHASE_ORDER_PATH,
  DOC_GEN_PURCHASE_ORDER_ADD_VIEW: `${DOC_GEN_PURCHASE_ORDER_PATH}/add`,
  DOC_GEN_PURCHASE_ORDER_EDIT_VIEW: `${DOC_GEN_PURCHASE_ORDER_PATH}/edit/:id`,
  DOC_GEN_PURCHASE_ORDER_DETAILS_VIEW: `${DOC_GEN_PURCHASE_ORDER_PATH}/view/:id`,

  //upload -doc_gen

  DOC_GEN_UPLOAD: DOC_GEN_UPLOAD_PATH,
  DOC_GEN_UPLOAD_LIST_VIEW: DOC_GEN_UPLOAD_PATH,

  //status-message
  STATUS_MESSAGE: STATUS_MESSAGE_PATH,
  STATUS_MESSAGE_LIST_VIEW: STATUS_MESSAGE_PATH,
  STATUS_MESSAGE_ADD_VIEW: `${STATUS_MESSAGE_PATH}/add`,
  STATUS_MESSAGE_EDIT_VIEW: `${STATUS_MESSAGE_PATH}/edit/:id`,
  STATUS_MESSAGE_DETAILS_VIEW: `${STATUS_MESSAGE_PATH}/view/:id`,

  //H2H SUB FAILS
  H2H_SUB_FAILS: H2H_SUB_FAILS_PATH,
  H2H_SUB_FAILS_LIST_VIEW: H2H_SUB_FAILS_PATH,
  H2H_SUB_FAILS_ADD_VIEW: `${H2H_SUB_FAILS_PATH}/add`,
  H2H_SUB_FAILS_EDIT_VIEW: `${H2H_SUB_FAILS_PATH}/edit/:id`,
  H2H_SUB_FAILS_DETAILS_VIEW: `${H2H_SUB_FAILS_PATH}/view/:id`,

  //File For User
  FILE_USER_PATH: FILE_USER_PATH,
  FILE_USER_LIST_VIEW: FILE_USER_PATH,

  //File for User(New version)
  FILE_FOR_USER_PATH: FILE_FOR_USER_PATH,
  FILE_FOR_USER_LIST_VIEW: FILE_FOR_USER_PATH,

  //INVOICE
  INVOICE: INVOICE_PATH,
  INVOICE_LIST_VIEW: INVOICE_PATH,
  INVOICE_ADD_VIEW: `${INVOICE_PATH}/add`,
  INVOICE_EDIT_VIEW: `${INVOICE_PATH}/edit/:id`,
  INVOICE_DETAILS_VIEW: `${INVOICE_PATH}/view/:id`,

  H_S_CODE: H_S_CODE_PATH,
  H_S_CODE_LIST_VIEW: H_S_CODE_PATH,
  H_S_CODE_ADD_VIEW: `${H_S_CODE_PATH}/add`,
  H_S_CODE_EDIT_VIEW: `${H_S_CODE_PATH}/edit/:id`,
  H_S_CODE_DETAILS_VIEW: `${H_S_CODE_PATH}/view/:id`,

  //upload SBCI
  SBCI_UPLOAD: SBCI_UPLOAD_PATH,
  SBCI_UPLOAD_LIST_VIEW: SBCI_UPLOAD_PATH,

  SBCI_FILE_UPLOAD_HISTORY: SBCI_FILE_UPLOAD_HISTORY_PATH,
  SBCI_FILE_UPLOAD_HISTORY_LIST_VIEW: SBCI_FILE_UPLOAD_HISTORY_PATH,
  SBCI_FILE_UPLOAD_HISTORY_ADD_VIEW: `${SBCI_FILE_UPLOAD_HISTORY_PATH}/add`,
  SBCI_FILE_UPLOAD_HISTORY_EDIT_VIEW: `${SBCI_FILE_UPLOAD_HISTORY_PATH}/edit/:id`,
  SBCI_FILE_UPLOAD_HISTORY_DETAILS_VIEW: `${SBCI_FILE_UPLOAD_HISTORY_PATH}/view/:id`,

  //SBCI INVOICE
  SBCI_INVOICE: SBCI_INVOICE_PATH,
  SBCI_INVOICE_LIST_VIEW: SBCI_INVOICE_PATH,
  SBCI_INVOICE_ADD_VIEW: `${SBCI_INVOICE_PATH}/add`,
  SBCI_INVOICE_ADD_EDIT_VIEW: `${SBCI_INVOICE_PATH}/add/:id`,
  SBCI_INVOICE_EDIT_VIEW: `${SBCI_INVOICE_PATH}/edit/:id`,
  SBCI_INVOICE_DETAILS_VIEW: `${SBCI_INVOICE_PATH}/view/:id`,

  //SBCI product Master
  SBCI_PRODUCT_MASTER: SBCI_PRODUCT_MASTER_PATH,
  SBCI_PRODUCT_MASTER_LIST_VIEW: SBCI_PRODUCT_MASTER_PATH,
  SBCI_PRODUCT_MASTER_ADD_VIEW: `${SBCI_PRODUCT_MASTER_PATH}/add`,
  SBCI_PRODUCT_MASTER_EDIT_VIEW: `${SBCI_PRODUCT_MASTER_PATH}/edit/:id`,
  SBCI_PRODUCT_MASTER_DETAILS_VIEW: `${SBCI_PRODUCT_MASTER_PATH}/view/:id`,

  //SBCI Manifest
  SBCI_MANIFEST: SBCI_MANIFEST_PATH,
  SBCI_MANIFEST_LIST_VIEW: SBCI_MANIFEST_PATH,
  SBCI_MANIFEST_ADD_VIEW: `${SBCI_MANIFEST_PATH}/add`,
  SBCI_MANIFEST_EDIT_VIEW: `${SBCI_MANIFEST_PATH}/edit/:id`,
  SBCI_MANIFEST_DETAILS_VIEW: `${SBCI_MANIFEST_PATH}/view/:id`,
  //Equipment Master - ACIHW
  EQUIPMENT_MASTER: EQUIPMENT_MASTER_PATH,
  EQUIPMENT_MASTER_LIST_VIEW: EQUIPMENT_MASTER_PATH,
  EQUIPMENT_MASTER_ADD_VIEW: `${EQUIPMENT_MASTER_PATH}/add`,
  EQUIPMENT_MASTER_EDIT_VIEW: `${EQUIPMENT_MASTER_PATH}/edit/:id`,
  EQUIPMENT_MASTER_DETAILS_VIEW: `${EQUIPMENT_MASTER_PATH}/view/:id`,

  //Conveyance Master - ACIHW
  CONV_MAST: CONVEYANCE_MASTER_PATH,
  CONV_MAST_LIST_VIEW: CONVEYANCE_MASTER_PATH,
  CONV_MAST_ADD_VIEW: `${CONVEYANCE_MASTER_PATH}/add`,
  CONV_MAST_EDIT_VIEW: `${CONVEYANCE_MASTER_PATH}/edit/:id`,
  CONV_MAST_DETAILS_VIEW: `${CONVEYANCE_MASTER_PATH}/view/:id`,

  //Status Query - ACIHW
  ACI_HW_STAT_QRY: ACI_HW_STAT_QRY_PATH,
  ACI_HW_STAT_QRY_LIST_VIEW: ACI_HW_STAT_QRY_PATH,
  ACI_HW_STAT_QRY_ADD_VIEW: `${ACI_HW_STAT_QRY_PATH}/add`,
  ACI_HW_STAT_QRY_EDIT_VIEW: `${ACI_HW_STAT_QRY_PATH}/edit/:id`,
  ACI_HW_STAT_QRY_DETAILS_VIEW: `${ACI_HW_STAT_QRY_PATH}/view/:id`,

  // ACI Highway 
  CREW_MASTER_PATH: CREW_MASTER_PATH,
  CREW_MASTER_LIST_VIEW: CREW_MASTER_PATH,
  CREW_MASTER_ADD_VIEW: `${CREW_MASTER_PATH}/add`,
  CREW_MASTER_EDIT_VIEW: `${CREW_MASTER_PATH}/edit/:id`,
  CREW_MASTER_DETAILS_VIEW: `${CREW_MASTER_PATH}/view/:id`,

  //Cargo - ACIHW
  ACI_HW_CARGO: ACI_HW_CARGO_PATH,
  ACI_HW_CARGO_LIST_VIEW: ACI_HW_CARGO_PATH,
  ACI_HW_CARGO_ADD_VIEW: `${ACI_HW_CARGO_PATH}/add`,
  ACI_HW_CARGO_EDIT_VIEW: `${ACI_HW_CARGO_PATH}/edit/:id`,
  ACI_HW_CARGO_DETAILS_VIEW: `${ACI_HW_CARGO_PATH}/view/:id`,
  ACI_HW_CARGO_UPLOAD_LIST_VIEW: `${ACI_HW_CARGO_PATH}/upload`,

  ACI_HW_D4_NOTICE: ACI_HW_D4_NOTICE_PATH,
  ACI_HW_D4_NOTICE_LIST_VIEW: ACI_HW_D4_NOTICE_PATH,
  EHBL_D4_NOTICE: EHBL_D4_NOTICE_PATH,
  EHBL_D4_NOTICE_LIST_VIEW: EHBL_D4_NOTICE_PATH,
  WACM_D4_NOTICE: WACM_D4_NOTICE_PATH,
  WACM_D4_NOTICE_LIST_VIEW: WACM_D4_NOTICE_PATH,

  ACI_HW_RELEASE_NOTICE: ACI_HW_RELEASE_NOTICE_PATH,
  ACI_HW_RELEASE_NOTICE_LIST_VIEW: ACI_HW_RELEASE_NOTICE_PATH,
  ACI_HW_RELEASE_NOTICE_ADD_VIEW: `${ACI_HW_RELEASE_NOTICE_PATH}/add`,
  ACI_HW_RELEASE_NOTICE_EDIT_VIEW: `${ACI_HW_RELEASE_NOTICE_PATH}/edit/:id`,
  ACI_HW_RELEASE_NOTICE_DETAILS_VIEW: `${ACI_HW_RELEASE_NOTICE_PATH}/view/:id`,


  ARTICLE_VIEW: `${HOME_PATH}/articles/:id`,
  EVENT_VIEW: `${HOME_PATH}/events/:id`,
  HOME_VIEW: HOME_PATH,
  HOME: HOME_PATH,
  LIST_VIEW: `${HOME_PATH}/:category`,
  NEWS_VIEW: `${HOME_PATH}/news/:id`,
  SEARCH_VIEW: `${HOME_PATH}/search`,
  SITE_CONTENT_VIEW: `${HOME_PATH}/site-content/:code`,
  TEST_VIEW: `/test`,
  VIDEO_VIEW: `${HOME_PATH}/videos/:id`,

  ACI_HW_RELEASE_NOTICE: ACI_HW_RELEASE_NOTICE_PATH,
  ACI_HW_RELEASE_NOTICE_LIST_VIEW: ACI_HW_RELEASE_NOTICE_PATH,

  ARTICLE_VIEW: `${HOME_PATH}/articles/:id`,
  EVENT_VIEW: `${HOME_PATH}/events/:id`,
  HOME_VIEW: HOME_PATH,
  HOME: HOME_PATH,
  LIST_VIEW: `${HOME_PATH}/:category`,
  NEWS_VIEW: `${HOME_PATH}/news/:id`,
  SEARCH_VIEW: `${HOME_PATH}/search`,
  SITE_CONTENT_VIEW: `${HOME_PATH}/site-content/:code`,
  TEST_VIEW: `/test`,
  VIDEO_VIEW: `${HOME_PATH}/videos/:id`,

  //NBCI
  US_PRODUCT_MASTER: US_PRODUCT_MASTER_PATH,
  US_PRODUCT_MASTER_LIST_VIEW: US_PRODUCT_MASTER_PATH,
  US_PRODUCT_MASTER_ADD_VIEW: `${US_PRODUCT_MASTER_PATH}/add`,
  US_PRODUCT_MASTER_EDIT_VIEW: `${US_PRODUCT_MASTER_PATH}/edit/:id`,
  US_PRODUCT_MASTER_DETAILS_VIEW: `${US_PRODUCT_MASTER_PATH}/view/:id`,
  US_DECLARATIONS: US_DECLARATIONS_PATH,
  US_DECLARATIONS_LIST_VIEW: US_DECLARATIONS_PATH,
  US_DECLARATIONS_ADD_VIEW: `${US_DECLARATIONS_PATH}/add`,
  US_DECLARATIONS_EDIT_VIEW: `${US_DECLARATIONS_PATH}/edit/:id`,
  US_DECLARATIONS_DETAILS_VIEW: `${US_DECLARATIONS_PATH}/view/:id`,
  NBCI_UPLOAD: `${NBCI_GENERAL_PATH}/upload`,

  //Aci Highway arrival
  ACI_HIGHWAY_ARRIVAL: ACI_HIGHWAY_ARRIVAL_PATH,
  ACI_HIGHWAY_ARRIVAL_LIST_VIEW: ACI_HIGHWAY_ARRIVAL_PATH,
  ACI_HIGHWAY_ARRIVAL_ADD_VIEW: `${ACI_HIGHWAY_ARRIVAL_PATH}/add`,
  ACI_HIGHWAY_ARRIVAL_EDIT_VIEW: `${ACI_HIGHWAY_ARRIVAL_PATH}/edit/:id`,
  ACI_HIGHWAY_ARRIVAL_DETAILS_VIEW: `${ACI_HIGHWAY_ARRIVAL_PATH}/view/:id`,

  //ACE Highway Shipment
  ACE_HIGHWAY_SHIPMENT: ACE_HIGHWAY_SHIPMENT_PATH,
  ACE_HIGHWAY_SHIPMENT_LIST_VIEW: ACE_HIGHWAY_SHIPMENT_PATH,
  ACE_HIGHWAY_SHIPMENT_ADD_VIEW: `${ACE_HIGHWAY_SHIPMENT_PATH}/add`,
  ACE_HIGHWAY_SHIPMENT_EDIT_VIEW: `${ACE_HIGHWAY_SHIPMENT_PATH}/edit/:id`,
  ACE_HIGHWAY_SHIPMENT_DETAILS_VIEW: `${ACE_HIGHWAY_SHIPMENT_PATH}/view/:id`,
  ACE_HIGHWAY_SHIPMENT_UPLOAD_VIEW: `${ACE_HIGHWAY_SHIPMENT_PATH}/upload`,

  //ACE Highway Manifest
  ACE_HIGHWAY_MANIFEST: ACE_HIGHWAY_MANIFEST_PATH,
  ACE_HIGHWAY_MANIFEST_LIST_VIEW: ACE_HIGHWAY_MANIFEST_PATH,
  ACE_HIGHWAY_MANIFEST_ADD_VIEW: `${ACE_HIGHWAY_MANIFEST_PATH}/add`,
  ACE_HIGHWAY_MANIFEST_EDIT_VIEW: `${ACE_HIGHWAY_MANIFEST_PATH}/edit/:id`,
  ACE_HIGHWAY_MANIFEST_DETAILS_VIEW: `${ACE_HIGHWAY_MANIFEST_PATH}/view/:id`,

  //ACE Highway Inbond Arrival
  ACE_HIGHWAY_INBOND_ARRIVAL: ACE_HIGHWAY_INBOND_ARRIVAL_PATH,
  ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW: ACE_HIGHWAY_INBOND_ARRIVAL_PATH,
  ACE_HIGHWAY_INBOND_ARRIVAL_ADD_VIEW: `${ACE_HIGHWAY_INBOND_ARRIVAL_PATH}/add`,
  ACE_HIGHWAY_INBOND_ARRIVAL_EDIT_VIEW: `${ACE_HIGHWAY_INBOND_ARRIVAL_PATH}/edit/:id`,
  ACE_HIGHWAY_INBOND_ARRIVAL_DETAILS_VIEW: `${ACE_HIGHWAY_INBOND_ARRIVAL_PATH}/view/:id`,

  //ACI Highway Inbond Arrival
  ACI_HIGHWAY_INBOND_ARRIVAL: ACI_HW_INBOND_ARRIVAL_PATH,
  ACI_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW: ACI_HW_INBOND_ARRIVAL_PATH,
  ACI_HIGHWAY_INBOND_ARRIVAL_ADD_VIEW: `${ACI_HW_INBOND_ARRIVAL_PATH}/add`,
  ACI_HIGHWAY_INBOND_ARRIVAL_EDIT_VIEW: `${ACI_HW_INBOND_ARRIVAL_PATH}/edit/:id`,
  ACI_HIGHWAY_INBOND_ARRIVAL_DETAILS_VIEW: `${ACI_HW_INBOND_ARRIVAL_PATH}/view/:id`,

  //Manifest Fax Tracker for ACE and ACI Highway
  MAN_FAX_TRAC_MAST: MANIFEST_FAX_TRACKER_PATH,
  MAN_FAX_TRAC_LIST_VIEW: MANIFEST_FAX_TRACKER_PATH,
  MAN_FAX_TRAC_ADD_VIEW: `${MANIFEST_FAX_TRACKER_PATH}/add`,
  MAN_FAX_TRAC_EDIT_VIEW: `${MANIFEST_FAX_TRACKER_PATH}/edit/:id`,
  MAN_FAX_TRAC_DETAILS_VIEW: `${MANIFEST_FAX_TRACKER_PATH}/view/:id`,

  //ABI Inbond 7512
  ABI_INBOND_7512: ABI_INBOND_7512_PATH,
  ABI_INBOND_7512_LIST_VIEW: ABI_INBOND_7512_PATH,
  ABI_INBOND_7512_ADD_VIEW: `${ABI_INBOND_7512_PATH}/add`,
  ABI_INBOND_7512_EDIT_VIEW: `${ABI_INBOND_7512_PATH}/edit/:id`,
  ABI_INBOND_7512_DETAILS_VIEW: `${ABI_INBOND_7512_PATH}/view/:id`,

  //ABI Inbond Event
  ABI_INBOND_EVENT: ABI_INBOND_EVENT_PATH,
  ABI_INBOND_EVENT_LIST_VIEW: ACE_HIGHWAY_INBOND_ARRIVAL_PATH,
  ABI_INBOND_EVENT_ADD_VIEW: `${ABI_INBOND_EVENT_PATH}/add`,
  ABI_INBOND_EVENT_EDIT_VIEW: `${ABI_INBOND_EVENT_PATH}/edit/:id`,
  ABI_INBOND_EVENT_DETAILS_VIEW: `${ABI_INBOND_EVENT_PATH}/view/:id`,

  //EHBL Manifest
  EHBL_MANIFEST: EHBL_MANIFEST_PATH,
  EHBL_MANIFEST_LIST_VIEW: EHBL_MANIFEST_PATH,
  EHBL_MANIFEST_ADD_VIEW: `${EHBL_MANIFEST_PATH}/add`,
  EHBL_MANIFEST_EDIT_VIEW: `${EHBL_MANIFEST_PATH}/edit/:id`,
  EHBL_MANIFEST_DETAILS_VIEW: `${EHBL_MANIFEST_PATH}/view/:id`,

  //EHBL House Bill
  EHBL_HOUSE_BILL_PATH: EHBL_HOUSE_BILL_PATH,
  EHBL_HOUSE_BILL_LIST_VIEW: EHBL_HOUSE_BILL_PATH,
  EHBL_HOUSE_BILL_ADD_VIEW: `${EHBL_HOUSE_BILL_PATH}/add`,
  EHBL_HOUSE_BILL_EDIT_VIEW: `${EHBL_HOUSE_BILL_PATH}/edit/:id`,
  EHBL_HOUSE_BILL_DETAILS_VIEW: `${EHBL_HOUSE_BILL_PATH}/view/:id`,

  //EHBL Close Message
  EHBL_CLOSE_MSG_PATH: EHBL_CLOSE_MSG_PATH,
  EHBL_CLOSE_MSG_LIST_VIEW: EHBL_CLOSE_MSG_PATH,
  EHBL_CLOSE_MSG_ADD_VIEW: `${EHBL_CLOSE_MSG_PATH}/add`,
  EHBL_CLOSE_MSG_EDIT_VIEW: `${EHBL_CLOSE_MSG_PATH}/edit/:id`,
  EHBL_CLOSE_MSG_DETAILS_VIEW: `${EHBL_CLOSE_MSG_PATH}/view/:id`,

  //EHBL Manifest Forward
  EHBL_MANIFEST_FORWARD_PATH: EHBL_MANIFEST_FORWARD_PATH,
  EHBL_MANIFEST_FORWARD_LIST_VIEW: EHBL_MANIFEST_FORWARD_PATH,
  EHBL_MANIFEST_FORWARD_ADD_VIEW: `${EHBL_MANIFEST_FORWARD_PATH}/add`,
  EHBL_MANIFEST_FORWARD_EDIT_VIEW: `${EHBL_MANIFEST_FORWARD_PATH}/edit/:id`,
  EHBL_MANIFEST_FORWARD_DETAILS_VIEW: `${EHBL_MANIFEST_FORWARD_PATH}/view/:id`,

  //Dashboard
  DASHBOARD_SUMMARY: DASHBOARD_SUMMARY_PATH,

  //DB Admin
  DB_ADMIN_STATUS_CONFIG: DB_ADMIN_STATUS_CONFIG_PATH,
  DB_ADMIN_STATUS_CONFIG_LIST_VIEW: DB_ADMIN_STATUS_CONFIG_PATH,
  DB_ADMIN_STATUS_CONFIG_ADD_VIEW: `${DB_ADMIN_STATUS_CONFIG_PATH}/add`,
  DB_ADMIN_STATUS_CONFIG_EDIT_VIEW: `${DB_ADMIN_STATUS_CONFIG_PATH}/edit/:id`,
  DB_ADMIN_STATUS_CONFIG_DETAILS_VIEW: `${DB_ADMIN_STATUS_CONFIG_PATH}/view/:id`,
  // NBCI US and CA party Master
  NBCI_USCA_PARTY_MASTER: NBCI_US_CA_PARTY_MASTER_PATH,
  NBCI_USCA_PARTY_MASTER_LIST_VIEW: NBCI_US_CA_PARTY_MASTER_PATH,
  NBCI_USCA_PARTY_MASTER_ADD_VIEW: `${NBCI_US_CA_PARTY_MASTER_PATH}/add`,
  NBCI_USCA_PARTY_MASTER_EDIT_VIEW: `${NBCI_US_CA_PARTY_MASTER_PATH}/edit/:id`,
  NBCI_USCA_PARTY_MASTER_DETAILS_VIEW: `${NBCI_US_CA_PARTY_MASTER_PATH}/view/:id`,

  //Canada Invoice
  N_B_C_I_CANADA_INVOICE: N_B_C_I_CANADA_INVOICE_PATH,
  N_B_C_I_CANADA_INVOICE_LIST_VIEW: N_B_C_I_CANADA_INVOICE_PATH,
  N_B_C_I_CANADA_INVOICE_ADD_VIEW: `${N_B_C_I_CANADA_INVOICE_PATH}/add`,
  N_B_C_I_CANADA_INVOICE_EDIT_VIEW: `${N_B_C_I_CANADA_INVOICE_PATH}/edit/:id`,
  N_B_C_I_CANADA_INVOICE_DETAILS_VIEW: `${N_B_C_I_CANADA_INVOICE_PATH}/view/:id`,


  //NBCI US invoice
  NBCI_US_INVOICE: NBCI_US_INVOICE_PATH,
  NBCI_US_INVOICE_LIST_VIEW: NBCI_US_INVOICE_PATH,
  NBCI_US_INVOICE_ADD_VIEW: `${NBCI_US_INVOICE_PATH}/add`,
  NBCI_US_INVOICE_EDIT_VIEW: `${NBCI_US_INVOICE_PATH}/edit/:id`,
  NBCI_US_INVOICE_DETAILS_VIEW: `${NBCI_US_INVOICE_PATH}/view/:id`,

  //ABOUT US
  HOME_PAGE_ABOUT_US: HOME_PAGE_ABOUT_US,

  //CONTACT US
  HOME_PAGE_CONTACT_US: HOME_PAGE_CONTACT_US,

  //PRODUCT INFO
  HOME_PAGE_PRODUCT_INFO: HOME_PAGE_PRODUCT_INFO,

  //TRADE RESOURCES
  HOME_PAGE_TRADE_RESOURCES: HOME_PAGE_TRADE_RESOURCES,

  //FAQ
  HOME_PAGE_FAQ: HOME_PAGE_FAQ,

  //PRIVACY POLICY  
  HOME_PAGE_PRIVACY_POLICY: HOME_PAGE_PRIVACY_POLICY,

  //TERMS OF USE
  HOME_PAGE_TERMS_OF_USE: HOME_PAGE_TERMS_OF_USE,

  //TERMS OF SERVICES
  HOME_PAGE_TERMS_OF_SERVICES: HOME_PAGE_TERMS_OF_SERVICES,

  //Announcement
  ANNOUNCEMENT: ANNOUNCEMENT_PATH,
  ANNOUNCEMENT_LIST_VIEW: ANNOUNCEMENT_PATH,
  ANNOUNCEMENT_ADD_VIEW: `${ANNOUNCEMENT_PATH}/add`,
  ANNOUNCEMENT_EDIT_VIEW: `${ANNOUNCEMENT_PATH}/edit/:id`,
  ANNOUNCEMENT_DETAILS_VIEW: `${ANNOUNCEMENT_PATH}/view/:id`,

  //WACM
  WACM_MMANIFEST_FORWARD: WACM_MF_NOTICE_PATH,
  WACM_MF_NOTICE_PATH_LIST_VIEW: WACM_MF_NOTICE_PATH,
  WACM_MANIFEST_FORWARD_DETAILS_VIEW: `${WACM_MF_NOTICE_PATH}/view/:id`,
  
  //ACE OCEAN
  ACE_OCN_MANIFEST_PATH: ACE_OCN_MANIFEST_PATH,
  ACE_OCN_MANIFEST_LIST_VIEW: ACE_OCN_MANIFEST_PATH,
  ACE_OCN_MANIFEST_ADD_VIEW: `${ACE_OCN_MANIFEST_PATH}/add`,
  ACE_OCN_MANIFEST_EDIT_VIEW: `${ACE_OCN_MANIFEST_PATH}/edit/:id`,
  ACE_OCN_MANIFEST_DETAILS_VIEW: `${ACE_OCN_MANIFEST_PATH}/view/:id`,
  
  ACE_OCN_BILLOFLADING: ACE_OCN_BILLOFLADING_PATH,
  ACE_OCN_BILLOFLADING_LIST_VIEW: ACE_OCN_BILLOFLADING_PATH,
  ACE_OCN_BILLOFLADING_ADD_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/add`,
  ACE_OCN_BILLOFLADING_EDIT_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/edit/:id`,
  ACE_OCN_BILLOFLADING_DETAILS_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/view/:id`,

  // ACE ISF 10
  ACEISF10: ACEISF_10_PATH,
  ACEISF10_LIST_VIEW: ACEISF_10_PATH,
  ACEISF10_ADD_VIEW: `${ACEISF_10_PATH}/add`,
  ACEISF10_EDIT_VIEW: `${ACEISF_10_PATH}/edit/:id`,
  ACEISF10_DETAILS_VIEW: `${ACEISF_10_PATH}/view/:id`,

  // ACE ISF 5
  ACEISF5: ACEISF_5_PATH,
  ACEISF5_LIST_VIEW: ACEISF_5_PATH,
  ACEISF5_ADD_VIEW: `${ACEISF_5_PATH}/add`,
  ACEISF5_EDIT_VIEW: `${ACEISF_5_PATH}/edit/:id`,
  ACEISF5_DETAILS_VIEW: `${ACEISF_5_PATH}/view/:id`,

//ACE OCEAN
  ACE_OCN_MANIFEST_PATH: ACE_OCN_MANIFEST_PATH,
  ACE_OCN_MANIFEST_LIST_VIEW: ACE_OCN_MANIFEST_PATH,
  ACE_OCN_MANIFEST_ADD_VIEW: `${ACE_OCN_MANIFEST_PATH}/add`,
  ACE_OCN_MANIFEST_EDIT_VIEW: `${ACE_OCN_MANIFEST_PATH}/edit/:id`,
  ACE_OCN_MANIFEST_DETAILS_VIEW: `${ACE_OCN_MANIFEST_PATH}/view/:id`,
  
  ACE_OCN_BILLOFLADING: ACE_OCN_BILLOFLADING_PATH,
  ACE_OCN_BILLOFLADING_LIST_VIEW: ACE_OCN_BILLOFLADING_PATH,
  ACE_OCN_BILLOFLADING_ADD_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/add`,
  ACE_OCN_BILLOFLADING_EDIT_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/edit/:id`,
  ACE_OCN_BILLOFLADING_DETAILS_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/view/:id`,
  //ACE OCEAN
  ACE_OCN_MANIFEST_PATH: ACE_OCN_MANIFEST_PATH,
  ACE_OCN_MANIFEST_LIST_VIEW: ACE_OCN_MANIFEST_PATH,
  ACE_OCN_MANIFEST_ADD_VIEW: `${ACE_OCN_MANIFEST_PATH}/add`,
  ACE_OCN_MANIFEST_EDIT_VIEW: `${ACE_OCN_MANIFEST_PATH}/edit/:id`,
  ACE_OCN_MANIFEST_DETAILS_VIEW: `${ACE_OCN_MANIFEST_PATH}/view/:id`,
  
  ACE_OCN_BILLOFLADING: ACE_OCN_BILLOFLADING_PATH,
  ACE_OCN_BILLOFLADING_LIST_VIEW: ACE_OCN_BILLOFLADING_PATH,
  ACE_OCN_BILLOFLADING_ADD_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/add`,
  ACE_OCN_BILLOFLADING_EDIT_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/edit/:id`,
  ACE_OCN_BILLOFLADING_DETAILS_VIEW: `${ACE_OCN_BILLOFLADING_PATH}/view/:id`,

  ACE_OCN_VESSEL_MASTER: ACE_OCN_VESSEL_MASTER_PATH,
  ACE_OCN_VESSEL_MASTER_LIST_VIEW: ACE_OCN_VESSEL_MASTER_PATH,
  ACE_OCN_VESSEL_MASTER_ADD_VIEW: `${ACE_OCN_VESSEL_MASTER_PATH}/add`,
  ACE_OCN_VESSEL_MASTER_EDIT_VIEW: `${ACE_OCN_VESSEL_MASTER_PATH}/edit/:id`,
  ACE_OCN_VESSEL_MASTER_DETAILS_VIEW: `${ACE_OCN_VESSEL_MASTER_PATH}/view/:id`,

  //Vessel Arrival
  ACE_OCN_VESSEL_ARRIVAL: ACE_OCN_VESSEL_ARRIVAL_PATH,
  ACE_OCN_VESSEL_ARRIVAL_LIST_VIEW: ACE_OCN_VESSEL_ARRIVAL_PATH,
  ACE_OCN_VESSEL_ARRIVAL_ADD_VIEW: `${ACE_OCN_VESSEL_ARRIVAL_PATH}/add`,
  ACE_OCN_VESSEL_ARRIVAL_EDIT_VIEW: `${ACE_OCN_VESSEL_ARRIVAL_PATH}/edit/:id`,
  ACE_OCN_VESSEL_ARRIVAL_DETAILS_VIEW: `${ACE_OCN_VESSEL_ARRIVAL_PATH}/view/:id`,
  //Vessel Departure
  ACE_OCN_VESSEL_DEPARTURE: ACE_OCN_VESSEL_DEPARTURE_PATH,
  ACE_OCN_VESSEL_DEPARTURE_LIST_VIEW: ACE_OCN_VESSEL_DEPARTURE_PATH,
  ACE_OCN_VESSEL_DEPARTURE_ADD_VIEW: `${ACE_OCN_VESSEL_DEPARTURE_PATH}/add`,
  ACE_OCN_VESSEL_DEPARTURE_EDIT_VIEW: `${ACE_OCN_VESSEL_DEPARTURE_PATH}/edit/:id`,
  ACE_OCN_VESSEL_DEPARTURE_DETAILS_VIEW: `${ACE_OCN_VESSEL_DEPARTURE_PATH}/view/:id`,



  //Party Config
  PARTY_CONFIG: PARTY_CONFIG_PATH,
  PARTY_CONFIG_LIST_VIEW: PARTY_CONFIG_PATH,
  PARTY_CONFIG_ADD_VIEW: `${PARTY_CONFIG_PATH}/add`,
  PARTY_CONFIG_EDIT_VIEW: `${PARTY_CONFIG_PATH}/edit/:id`,
  PARTY_CONFIG_DETAILS_VIEW: `${PARTY_CONFIG_PATH}/view/:id`,


  //AES Shipment
  AES_SHIPMENT: AES_SHIPMENT_PATH,
  AES_SHIPMENT_LIST_VIEW: AES_SHIPMENT_PATH,
  AES_SHIPMENT_ADD_VIEW: `${AES_SHIPMENT_PATH}/add`,
  AES_SHIPMENT_EDIT_VIEW: `${AES_SHIPMENT_PATH}/edit/:id`,
  AES_SHIPMENT_DETAILS_VIEW: `${AES_SHIPMENT_PATH}/view/:id`,

  //AES Party Screening
  AES_PARTY_SCREENING: AES_PARTY_SCREENING_PATH,
  AES_PARTY_SCREENING_LIST_VIEW: AES_PARTY_SCREENING_PATH,
  AES_PARTY_SCREENING_ADD_VIEW: `${AES_PARTY_SCREENING_PATH}/add`,
  AES_PARTY_SCREENING_EDIT_VIEW: `${AES_PARTY_SCREENING_PATH}/edit/:id`,
  AES_PARTY_SCREENING_DETAILS_VIEW: `${AES_PARTY_SCREENING_PATH}/view/:id`,

  //AES Product Master
  AES_PRODUCT_MASTER: AES_PRODUCT_MASTER_PATH,
  AES_PRODUCT_MASTER_LIST_VIEW: AES_PRODUCT_MASTER_PATH,
  AES_PRODUCT_MASTER_ADD_VIEW: `${AES_PRODUCT_MASTER_PATH}/add`,
  AES_PRODUCT_MASTER_EDIT_VIEW: `${AES_PRODUCT_MASTER_PATH}/edit/:id`,
  AES_PRODUCT_MASTER_DETAILS_VIEW: `${AES_PRODUCT_MASTER_PATH}/view/:id`,
  // FDAPN
  FDAPN: FDAPN_PATH,
  FDAPN_LIST_VIEW: FDAPN_PATH,
  FDAPN_ADD_VIEW: `${FDAPN_PATH}/add`,
  FDAPN_EDIT_VIEW: `${FDAPN_PATH}/edit/:id`,
  FDAPN_DETAILS_VIEW: `${FDAPN_PATH}/view/:id`,

  // FDAPN Product Master
  FDAPN_PRODUCTMASTER: FDAPN_PRODUCTMASTER_PATH,
  FDAPN_PRODUCTMASTER_LIST_VIEW: FDAPN_PRODUCTMASTER_PATH,
  FDAPN_PRODUCTMASTER_ADD_VIEW: `${FDAPN_PRODUCTMASTER_PATH}/add`,
  FDAPN_PRODUCTMASTER_EDIT_VIEW: `${FDAPN_PRODUCTMASTER_PATH}/edit/:id`,
  FDAPN_PRODUCTMASTER_DETAILS_VIEW: `${FDAPN_PRODUCTMASTER_PATH}/view/:id`
  

}

export default pathMap