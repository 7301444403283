import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: '#707070',
    padding: '1rem',
    marginTop: '1rem'
  }
}))

function Footer() {
  const classes = useStyles()

  return (
    <Box className={classes.root} />
  )
}

export default Footer
