import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, Button } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    button: {
        fontSize: 16,
        fontWeight: 400,
        textTransform: 'none',
        transition: 'all 0.15s linear',
        borderRadius: 10,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#113448',
            boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08), 0px 6px 12px -2px rgba(0, 0, 0, 0.09), 0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
        }
    }
}))

function Home({ defaultPagePath }) {
    const classes = useStyles()
    const history = useHistory()

    return <Button className={classes.button} onClick={() => history.push(defaultPagePath)} size='medium'>Home</Button>
}

export default Home;