import React from 'react'
import { Box, Grid, Typography, IconButton, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    padding: '1rem'
  }
}))

function Footer() {
  const classes = useStyles()

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  return (
    <Box className={classes.root}>
      <Grid container justify='center' spacing={2}>
        <IconButton onClick={() => openWindow('https://facebook.com/RBI.eManifest/')}>
          <FontAwesomeIcon icon='fa-brands fa-facebook' size='xl' />
        </IconButton>
        <IconButton onClick={() => openWindow('https://www.linkedin.com/company/rbi-canada/')}>
          <span className='fa-layers fa-fw fa-xl'>
            <FontAwesomeIcon icon='fas fa-circle' />
            <FontAwesomeIcon icon='fab fa-linkedin-in' color='white' transform={'shrink-6'} />
          </span>
        </IconButton>
        <IconButton onClick={() => openWindow('mailto:info@rbicanada.com')}>
          <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-envelope' size='xl' />
        </IconButton>
      </Grid>
      <Grid container justify='center' style={{ marginTop: '5px' }}>
        <Typography variant='h5' style={{ fontWeight: 400 }}>
          All rights reserved - RBI Canada 2023 | <span style={{ color: '#007bff' }}>Version francaise Conception Virtuel Graphique</span>
        </Typography>
      </Grid>
    </Box>
  )
}

export default Footer
